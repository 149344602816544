import { useEffect, useState } from 'react';
import { BarLoader } from 'react-spinners';
import { InputMode, OptionType } from '../../state/userinput';

interface Props {
  activeSegment: OptionType | undefined;
  activeVessel: OptionType | null;
  inputMode: InputMode;
  leadTime: number;
  currentMapPos: { lat: number; lng: number };
  isLoading: boolean;
}
export const MapInfo = (props: Props) => {
  const { activeSegment, activeVessel, inputMode, leadTime, currentMapPos, isLoading } = props;
  const [positioning, setPositioning] = useState('');

  useEffect(() => {
    const updatePositioning = () => {
      setPositioning(window.innerWidth > 1000 ? '-50%' : '-10%');
    };
    window.addEventListener('resize', updatePositioning);
    updatePositioning();

    return () => window.removeEventListener('resize', updatePositioning);
  }, []);

  return (
    <div style={{ position: 'absolute', left: '50%', height: 0 }}>
      <div
        style={{
          textAlign: 'center',
          position: 'relative',
          left: positioning,
          zIndex: 2,
        }}
      >
        <span className='badge-sm badge-pill badge-dark d-inline-block mt-1'>
          <strong>
            {inputMode === InputMode.trackVessel && (
              <span style={{ color: 'yellow' }}>TRACK VESSEL</span>
            )}
            {inputMode === InputMode.singleTrip && (
              <span style={{ color: 'lime' }}>VIEW TRADE</span>
            )}
          </strong>
        </span>
        <span className='badge-sm badge-pill badge-dark d-inline-block mt-1'>
          <strong>Vessel:</strong> {activeVessel?.label}
        </span>
        <span className='badge-sm badge-pill badge-dark d-inline-block mt-1'>
          <strong>Segment:</strong> {activeSegment?.label}
        </span>
        <span className='badge-sm badge-pill badge-dark d-inline-block mt-1'>
          <strong>Map Pos:</strong> {currentMapPos.lat.toFixed(1)}, {currentMapPos.lng.toFixed(1)}
        </span>
        <span className='badge-sm badge-pill badge-dark d-inline-block mt-1'>
          <strong>Lead (d) ±:</strong> {leadTime}
        </span>
        {isLoading && (
          <BarLoader
            loading={isLoading}
            height={4}
            width={200}
            cssOverride={{
              display: 'block',
              margin: 'auto',
            }}
          />
        )}
      </div>
    </div>
  );
};
