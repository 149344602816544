export const FirstPage = (props: { handleLogin: () => void }) => {
  return (
    <div
      style={{
        background: 'url("/images/firstpage.jpeg") center/cover',
        width: '100vw',
        height: '100vh',
      }}
    >
      <div
        style={{
          width: 440,
          height: 275,
          position: 'relative',
          left: 135,
          top: 150,
          boxShadow: '0px 0px 50px',
          backgroundColor: 'rgb(245, 245, 245,0.5)',
          borderRadius: '5px',
          textAlign: 'center',
          paddingTop: 20,
        }}
      >
        <div
          style={{
            fontFamily: 'Monoton',
            fontSize: '2em',
            color: '#007BFF',
            textShadow: '1px 2px 10px grey',
          }}
        >
          R.I.T.A
        </div>
        <br />
        <h1
          style={{
            color: '#23538F',
            fontWeight: '700',
            textShadow: '1px 2px 10px grey',
          }}
        >
          Astrup Fearnley
        </h1>
        <button className='btn btn-outline-primary mt-5' onClick={props.handleLogin}>
          Continue ➥
        </button>
      </div>
      <footer
        style={{ backgroundColor: '#235B8F' }}
        className='navbar navbar-dark fixed-bottom navbar-expand row pt-3 pb-3'
      >
        <div className='col-3 pl-5'>
          <img
            style={{ height: 20, marginRight: 10, marginTop: '-5px' }}
            src='/images/knute-1.png'
            alt='logo'
          />
          <h5 className='d-inline-block'>Fearnley</h5>
          <p className='mb-0'>Servicing the shipping industry since 1869</p>
          <p className='mb-0'>
            <small>© 2021. All Rights Reserved.</small>
          </p>
        </div>
        <div
          className='collapse navbar-collapse col-6 d-flex justify-content-center'
          id='navbarTogglerDemo03'
        >
          <div>
            <ul className='navbar-nav mr-auto mt-2 mt-lg-0 '>
              <li className='nav-item'>
                <a className='nav-link' href='https://fearnleyoffshoresupply.com/story/'>
                  About
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='https://fearnleyoffshoresupply.com/services/'>
                  Our Business Areas
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='https://fearnleyoffshoresupply.com/contact-oslo-office/'
                >
                  Contact
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='https://astrupfearnley.com/'>
                  Astrup Fearnley
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='text-right col-3 pr-5 '>
          <div className='h5'>Location</div>
          <p className='mb-0'>Dronning Eufemias Gate 8</p>
          <p className='mb-0'>Oslo | Norway +47 22 93 60 00</p>
        </div>
      </footer>
    </div>
  );
};
