import { toast } from 'react-toastify';
import { msalInstance } from '../..';
import { getAPIPath } from '../../core';
import { Trade } from '../../state/trades';
import { InputMode, OptionType } from '../../state/userinput';
import { SortBy } from '../common/types';

export const getRequestAccessToken = async () => {
  const request = {
    scopes: ['59ff9420-05ab-4508-9f9c-03dbb3fe9d8b/.default'],
    loginHint: msalInstance.getActiveAccount()?.username,
  };

  try {
    const res = await msalInstance.acquireTokenSilent(request);
    return res.accessToken;
  } catch (error) {
    const res = await msalInstance.acquireTokenPopup(request);
    return res.accessToken;
  }
};

const isJson = (response: any) =>
  response.headers.get('content-type')?.includes('application/json');

export const getTradesBySegment = async (
  segment: string,
  type: string,
  dateFrom: Date,
  dateTo: Date,
  page: number,
  perPage: number,
  sortBy: SortBy[],
) => {
  const params = {
    dateFrom: dateFrom.toString(),
    dateTo: dateTo.toString(),
    sortBy,
    currentPage: page.toString(),
    perPage: perPage.toString(),
  };

  const accessToken = await getRequestAccessToken();

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(
    `${getAPIPath()}/api/ship-track/rita/last-trades/${segment}/${type}/`,
    {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      headers,
      body: JSON.stringify(params),
    },
  );

  const data = isJson(response) ? await response.json() : null;

  if (!response.ok) {
    const error = (data && data.message) || response.status;
    toast.error(`${error} ${response.statusText}`);

    return Promise.reject(error);
  } else {
    return data;
  }
};

export const getTradesBySegmentAndVessel = async (
  segment: string,
  vessel: string,
  dateFrom: Date,
  dateTo: Date,
  page: number,
  perPage: number,
  sortBy: SortBy[],
) => {
  const params = {
    dateFrom: dateFrom.toString(),
    dateTo: dateTo.toString(),
    sortBy: sortBy,
    currentPage: page.toString(),
    perPage: perPage.toString(),
  };

  // const query = new URLSearchParams(params);

  const accessToken = await getRequestAccessToken();

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${getAPIPath()}/api/ship-track/last-trades/${segment}/${vessel}/`, {
    method: 'POST',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers,
    body: JSON.stringify(params),
  });

  const data = isJson(response) ? await response.json() : null;

  if (!response.ok) {
    const error = (data && data.message) || response.status;
    toast.error(`${error} ${response.statusText}`);

    return Promise.reject(error);
  } else {
    return data;
  }
};

interface Vessel {
  Imo: string;
  ShipName: string;
}
export const getVesselListQuery = async (activeSegment: OptionType | undefined) => {
  if (activeSegment === undefined) return [];
  if (activeSegment.value === '') return [];

  try {
    const accessToken = await getRequestAccessToken();

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);
    headers.append('Content-Type', 'application/json');
    const resp = await fetch(`${getAPIPath()}/api/ship-track/vessels/${activeSegment.value}`, {
      credentials: 'include',
      headers,
    });
    const data = (await resp.json()).map((el: any) => {
      return {
        Imo: el.imo,
        ShipName: el.shipname,
      };
    });

    return data as Vessel[];
  } catch (err) {
    return [] as Vessel[];
  }
};

export interface ParamsTradeSearch {
  dateFrom: Date;
  dateTo: Date;
  activeSegment: OptionType | undefined;
  activeVessel: OptionType | null;
  inputMode: InputMode;
  activeTrade: Trade | undefined;
  leadTime: number;
}
export const getVesselsTradeDetails = async (params: ParamsTradeSearch) => {
  const { dateFrom, dateTo, activeSegment, activeVessel, inputMode, activeTrade, leadTime } =
    params;
  if (activeSegment === undefined) return undefined;
  if (activeSegment.value === '') return undefined;

  if (!activeVessel) return undefined;

  let interval = {};
  if (inputMode === InputMode.singleTrip) {
    if (activeTrade === undefined) return undefined;

    let tarDate = new Date(activeTrade.arriveDateTimestamp);

    interval = {
      dateFrom: activeTrade.departDate ?? new Date().toString(),
      dateTo: tarDate.toString(),
      leadTime,
    };
  } else if (inputMode === InputMode.trackVessel) {
    interval = {
      dateFrom: dateFrom.toString(),
      dateTo: dateTo.toString(),
      leadTime,
    };
  }

  const query = new URLSearchParams(interval);
  const accessToken = await getRequestAccessToken();

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  console.log(
    'fetch',
    `${getAPIPath()}/api/ship-track/trade/${activeSegment.value}/${activeVessel.value}/?${query}`,
  );
  const response = await fetch(
    `${getAPIPath()}/api/ship-track/trade/${activeSegment.value}/${activeVessel.value}/?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      headers,
    },
  );

  const data = isJson(response) ? await response.json() : null;

  if (!response.ok) {
    const error = (data && data.message) || response.status;
    toast.error(`${error} ${response.statusText}`);

    return Promise.reject(error);
  } else {
    return data;
  }
};

export type PortsData = {
  lat1: number;
  long1: number;
  destination: string;
  country: string;
  region: string;
  type: string;
  isNew?: boolean;
  createdAt: string;
  createdBy: string;
};

export const getPorts = async (activeSegment: OptionType | undefined) => {
  if (activeSegment === undefined) return [];
  if (activeSegment?.value === '') return [];

  const accessToken = await getRequestAccessToken();

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${getAPIPath()}/api/ship-track/ports/${activeSegment.value}`, {
    credentials: 'include',
    headers,
  });

  const data = isJson(response) ? await response.json() : null;

  if (!response.ok) {
    const error = (data && data.message) || response.status;
    toast.error(`${error} ${response.statusText}`);

    return Promise.reject(error);
  } else {
    return data;
  }
};

export const getNewPorts = async (activeSegment: OptionType | undefined) => {
  if (activeSegment === undefined) return [];
  if (activeSegment?.value === '') return [];

  const accessToken = await getRequestAccessToken();

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${getAPIPath()}/api/ship-track/newports/${activeSegment.value}`, {
    credentials: 'include',
    headers,
  });

  const data = isJson(response) ? await response.json() : null;

  if (!response.ok) {
    const error = (data && data.message) || response.status;
    toast.error(`${error} ${response.statusText}`);

    return Promise.reject(error);
  } else {
    return data;
  }
};

export const addPort = async (
  lat1: number,
  long1: number,
  destination: string,
  region: string,
  country: string,
  segment: string,
  userName: string,
) => {
  const accessToken = await getRequestAccessToken();

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Content-Type', 'application/json');
  const res = await fetch(`${getAPIPath()}/api/ship-track/newports/add-port`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      lat1,
      long1,
      destination,
      region,
      country,
      type: segment,
      createdBy: userName,
    }),
    credentials: 'include',
  });

  return res;
};

export const removePort = async (lat: number, long: number, segment: string) => {
  const accessToken = await getRequestAccessToken();

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Content-Type', 'application/json');
  const res = await fetch(`${getAPIPath()}/api/ship-track/newports/remove-port`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ lat, long, segment }),
    credentials: 'include',
  });

  return res;
};
