import dotenv from 'dotenv';
import appSettings from '../src/appSettings.json';
dotenv.config();

export const isDevMode = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
};

export const getAPIPath = () => {
  if (isDevMode()) {
    return `http://localhost:3001`;
  } else {
    // https://rita-backend.fearnpulse.com
    return appSettings.Global.apiUrl;
  }
};

export const segments = [
  {
    name: 'Aframax',
    key: 'aframax',
  },
  {
    name: 'Capesize',
    key: 'capesize',
  },
  {
    name: 'LGC',
    key: 'lgc',
  },
  {
    name: 'LNG',
    key: 'lng',
  },
  {
    name: 'Panamax',
    key: 'panamax',
  },
  {
    name: 'Panamax Bulk',
    key: 'panamax_bulk',
  },
  {
    name: 'Suezmax',
    key: 'suezmax',
  },
  {
    name: 'Supramax',
    key: 'supramax',
  },
  {
    name: 'VLCC',
    key: 'vlcc',
  },
  {
    name: 'VLGC',
    key: 'vlgc',
  },
  {
    name: 'Vloc',
    key: 'vloc',
  },
];

export enum UrlPaths {
  TRACK_VESSEL = '/track/vessel',
  TRACK_VESSEL_MAP = '/track/vessel/map',
  TRACK_TRADE = '/track/trade',
  TRACK_TRADE_MAP = '/track/trade/map',
  INSPECT_TRADES = '/inspect/trades',
  PORT_UPDATES = '/inspect/portupdates',
}
