import * as ls from 'local-storage';
import { defaultValues } from './defaultValues';
import { Trade } from './trades';
import { InputMode, OptionType } from './userinput';

export type Presets = {
  [key: string]: UserPresets;
};

type UserPresets = {
  activeSegment: OptionType | undefined;
  activeVessel: OptionType | null;
  dateFrom: string;
  dateTo: string;
  leadTime: string;
  inputMode: string;
  activeTrade: Trade | undefined;
  savedPath:string;
};

export const getUserPresets = (currentUser: string) => {
  ls.set('currentUser', currentUser);

  if (!ls.get('presets')) ls.set('presets', '{}');
  const presets = JSON.parse(ls.get('presets') as string) as Presets;

  if (presets[currentUser]) return presets[currentUser];
  else {
    //initiate the storage
    presets[currentUser] = {
      activeSegment: defaultValues.segment as OptionType | undefined,
      activeVessel: defaultValues.activeVessel as OptionType | null,
      dateFrom: defaultValues.dateFrom.toString(),
      dateTo: defaultValues.dateTo.toString(),
      leadTime: '0',
      inputMode: InputMode.singleTrip.toString(),
      activeTrade: undefined,
      savedPath:'/track/trade',
    };

    ls.set('presets', JSON.stringify(presets));
    return undefined;
  }
};

type PresetName =
  | 'activeSegment'
  | 'activeVessel'
  | 'dateFrom'
  | 'dateTo'
  | 'leadTime'
  | 'inputMode'
  | 'activeTrade';

export const savePresets = (newPreset: any, presetName: PresetName) => {
  const currentUser = ls.get('currentUser') as string;
  const presets = JSON.parse(ls.get('presets') as string) as Presets;

  presets[currentUser][presetName] = newPreset;
  ls.set('presets', JSON.stringify(presets));
};

export const getSavedMapData = () => {
  const currentUser = ls.get('currentUser') as string;

  if (!currentUser) return undefined;

  const presets = JSON.parse(ls.get('presets') as string) as Presets;
  const userSet = presets[currentUser];

  return {
    activeSegment: userSet.activeSegment,
    activeVessel: userSet.activeVessel,
    dateFrom: new Date(userSet.dateFrom),
    dateTo: new Date(userSet.dateTo),
    leadTime: +userSet.leadTime,
    inputMode: +userSet.inputMode,
    activeTrade: userSet.activeTrade,
  };
};

export const getLastSavedValue = (presetName: PresetName) => {
  const currentUser = ls.get('currentUser') as string;

  if (!currentUser) return undefined;

  const presets = JSON.parse(ls.get('presets') as string) as Presets;

  return presets[currentUser][presetName];
};

export const saveLocation = (path:string) => {
  const currentUser = ls.get('currentUser') as string;
  const presets = JSON.parse(ls.get('presets') as string) as Presets;
  presets[currentUser].savedPath = path;
  ls.set('presets', JSON.stringify(presets));
}
