import { TradeWithVisualExtra } from '../../state/trades';
import { AFTableDateCell } from '../common/table/AFTableDateCell';
import { AFTableDateTimeCell } from '../common/table/AFTableDateTimeCell';
import { TableActionsCell } from './TableActionsCell';
import { TableTradeLinkCell } from './TableTradeLinkCell';

const defaultColumns = [
  {
    Header: 'Imo',
    accessor: 'Imo',
  },
  {
    Header: 'Ship Name',
    accessor: 'ShipName',
    Cell: ({ row }: any) => (
      <TableTradeLinkCell
        trade={row.original as TradeWithVisualExtra}
        value={row.original.ShipName}
      />
    ),
  },
  {
    Header: 'Load Port',
    accessor: 'loadPort',
    Cell: ({ row }: any) => (
      <>
        {row.original.loadPort}, {row.original.loadCountry}
      </>
    ),
  },
  {
    Header: 'Depart Date',
    accessor: 'departDate',
    Cell: (cell: any) => <AFTableDateCell value={cell.value} />,
  },
  {
    Header: 'Discharge Port',
    accessor: 'dischargePort',
    Cell: ({ row }: any) => (
      <>
        {row.original.dischargePort}, {row.original.dischargeCountry}
      </>
    ),
  },
  {
    Header: 'Arrive Date',
    accessor: 'arriveDate',
    Cell: (cell: any) => <AFTableDateCell value={cell.value} />,
  },
  {
    Header: 'Discharge',
    accessor: 'discharge',
  },
  {
    Header: 'Discharges',
    accessor: 'discharges',
  },
  {
    Header: 'Estimated Discharge Pct',
    accessor: 'EstimatedDischargePct',
  },
  {
    Header: 'Arrive Draught B',
    accessor: 'ArriveDraughtB',
  },
  {
    Header: 'Draught Change B',
    accessor: 'DraughtChangeB',
  },
  {
    Header: 'Certainty',
    accessor: 'certainty',
  },
  {
    Header: 'Distance',
    accessor: 'EstDistance',
    Cell: (cell: any) => parseFloat(cell.value).toFixed(2),
  },
  {
    Header: 'DWT Miles',
    accessor: 'DwtMiles',
  },

  {
    Header: 'Ton Miles',
    accessor: 'TonMiles',
  },
  {
    Header: 'Cabotage',
    accessor: 'cabotage',
  },
];

const auditColumns = [
  ...defaultColumns,
  {
    Header: 'Audit Note',
    accessor: 'note',
  },
  {
    Header: 'Audit Timestamp',
    accessor: 'auditCreateTime',
    Cell: (cell: any) => <AFTableDateTimeCell value={cell.value} />,
  },
];

export const columns = (audit: boolean) => [
  ...(audit ? auditColumns : defaultColumns),
  {
    Header: 'Actions',
    Cell: ({ row }: any) => <TableActionsCell trade={row.original as TradeWithVisualExtra} />,
  },
];
