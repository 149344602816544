import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { getUserPresets, saveLocation } from '../state/presetsStorage';
import { activeTradeState } from '../state/trades';
import {
  activeVesselState,
  dateFromState,
  dateToState,
  inputModeState,
  leadTimeState,
  segment,
} from '../state/userinput';
import { LatestTrades } from './LatestTrades/LatestTrades';
import { MapContainer } from './Map/MapContainer';
import { PortsMapContainer } from './Map/PortsMapContainer';
import { Theme } from './Theme';
import { TradeSearch } from './TradeSearch/TradeSearch';

export const LoggedInRoot = (props: any) => {
  const location = useLocation();
  const [, setDateFrom] = useRecoilState(dateFromState);
  const [, setDateTo] = useRecoilState(dateToState);
  const [, setLeadTime] = useRecoilState(leadTimeState);
  const [, setActiveSegment] = useRecoilState(segment);
  const [, setActiveVessel] = useRecoilState(activeVesselState);
  const [, setInputMode] = useRecoilState(inputModeState);
  const [, setActiveTrade] = useRecoilState(activeTradeState);
  const { accounts } = useMsal();
  const history = useHistory();

  useBeforeunload(() => {
    saveLocation(location.pathname);
  });

  useEffect(() => {
    const currentUser = accounts[0].name as string;
    const userPresets = getUserPresets(currentUser);
  
    if (userPresets) {
      setActiveSegment(userPresets.activeSegment);
      setActiveVessel(userPresets.activeVessel);
      setDateFrom(new Date(userPresets.dateFrom));
      setDateTo(new Date(userPresets.dateTo));
      setLeadTime(+userPresets.leadTime);
      setInputMode(+userPresets.inputMode);
      setActiveTrade(userPresets.activeTrade as any);
      history.push(userPresets.savedPath);
    }
  }, []);

  return (
    <Switch>
      <Route exact={true} path='/track/vessel/map'>
        <Theme displayMenu={true}>
          <MapContainer />
        </Theme>
      </Route>

      <Route exact={true} path='/track/trade/map'>
        <Theme displayMenu={true}>
          <MapContainer />
        </Theme>
      </Route>

      <Route exact={true} path='/track/trade'>
        <Theme displayMenu={true}>
          <TradeSearch />
        </Theme>
      </Route>

      <Route path='/inspect/trades'>
        <Theme displayMenu={true}>
          <LatestTrades />
        </Theme>
      </Route>

      <Route path='/inspect/portupdates'>
        <Theme displayMenu={true}>
          <PortsMapContainer />
        </Theme>
      </Route>

      <Route path='/'>
        <Redirect to='/track/trade' />
        {/* <Theme displayMenu={true}>
            <div>
              <h2>Welcome to R.I.T.A</h2>
            </div>
          </Theme> */}
      </Route>
    </Switch>
  );
};
