import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './MapGoToModal';
import {
  OptionType,
  activeVesselState,
  inputModeState,
  InputMode,
} from '../../state/userinput';
import { UrlPaths } from '../../core';
import { useRecoilState } from 'recoil';
import {
  Trade,
  openTradeModalState,
  openTradeSelectTradeState,
} from '../../state/rita';
import { TradeSummary } from './MarkTradeInvalidModal';
import { activeTradeState } from '../../state/trades';
import { useHistory } from 'react-router-dom';

export const OpenTradeModal = () => {
  // FIXME: Cleanup!! Code duplication
  const [, setActiveVessel] = useRecoilState(activeVesselState);
  const [, setActiveTrade] = useRecoilState(activeTradeState);
  const [, setInputMode] = useRecoilState(inputModeState);

  const [openTradeModal, setOpenTradeModal] = useRecoilState(openTradeModalState);
  const [openTradeSelectTrade, setOpenTradeSelectTrade] = useRecoilState(
    openTradeSelectTradeState
  );

  const history = useHistory();

  const trackVessel = () => {
    setActiveVessel({
      value: openTradeSelectTrade?.Imo,
      label: `${openTradeSelectTrade?.ShipName} (${openTradeSelectTrade?.Imo})`,
    } as OptionType);

    setActiveTrade(undefined);
    setInputMode(InputMode.trackVessel);
    setOpenTradeModal(false);

    history.push(UrlPaths.TRACK_VESSEL_MAP);
  };

  const openTrade = () => {
    setActiveVessel({
      value: openTradeSelectTrade?.Imo,
      label: `${openTradeSelectTrade?.ShipName} (${openTradeSelectTrade?.Imo})`,
    } as OptionType);
    
    setActiveTrade(openTradeSelectTrade as Trade);
    setInputMode(InputMode.singleTrip);
    setOpenTradeModal(false);

    history.push(UrlPaths.TRACK_TRADE_MAP);
  };

  return (
    <Modal
      isOpen={openTradeModal}
      ariaHideApp={false}
      onAfterOpen={() => {}}
      onRequestClose={() => {}}
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          minWidth: 600,
          minHeight: 400,
          marginRight: 'auto',
        },
      }}
      contentLabel="Example Modal"
    >
      <>
        <h4>View trade</h4>

        <p>Do you want to view the trade, or track the vessels movements?</p>

        {openTradeSelectTrade !== null && (
          <TradeSummary trade={openTradeSelectTrade} />
        )}

        <button className="btn btn-primary modal-button" onClick={openTrade}>
          View trade
        </button>

        <button className="btn btn-primary modal-button" onClick={trackVessel}>
          Track vessel (view vessel)
        </button>

        <button
          className="btn btn-secondary"
          onClick={() => {
            setOpenTradeModal(false);
          }}
        >
          Cancel
        </button>
      </>
    </Modal>
  );
};
