import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from './MapGoToModal';
import { segment } from '../../state/userinput';
import { getAPIPath } from '../../core';
import { AppLoader } from '../common/AppLoader';
import { useRecoilState } from 'recoil';
import { restoreValidModalState, restoreValidSelectTradeState } from '../../state/rita';
import { toast } from 'react-toastify';
import { TradeSummary } from './MarkTradeInvalidModal';
import { getRequestAccessToken } from '../services/service';

export const RestoreTradeValidModal = () => {
  const [restoreValidModal, setRestoreValidModalState] = useRecoilState(restoreValidModalState);

  const [restoreValidSelectTrade, setRestoreValidSelectTrade] = useRecoilState(
    restoreValidSelectTradeState,
  );

  const [segmentVal, setSegment] = useRecoilState(segment);

  const [noteState, setNoteState] = useState('');
  const [isPosting, setIsPosting] = useState(false);

  const submitRestoreValid = async () => {
    const bodyRaw = {
      arriveDateTimestamp: restoreValidSelectTrade?.arriveDateTimestamp,
      IMO: restoreValidSelectTrade?.Imo,
      note: noteState,
      segment: segmentVal?.value,
    };

    setIsPosting(true);

    try {
      const accessToken = await getRequestAccessToken();

      const headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append('Authorization', bearer);
      headers.append('Content-Type', 'application/json');
      const resp = await fetch(`${getAPIPath()}/api/ship-track/rita/restore-trade`, {
        method: 'POST',
        headers,
        body: JSON.stringify(bodyRaw),
        credentials: 'include',
      });
      setIsPosting(false);

      if (resp.status !== 200) {
        setRestoreValidModalState(false);
        return toast.error('An error occurred!');
      }

      const data = await resp.json();
      toast.success(
        `Trade ${bodyRaw.IMO}${bodyRaw.arriveDateTimestamp} has been restored as valid.`,
        {},
      );
      setNoteState('');
      setRestoreValidModalState(false);

      return data;
    } catch (err) {
      setIsPosting(false);
      setNoteState('');
      return [];
    }
  };

  return (
    <Modal
      isOpen={restoreValidModal}
      ariaHideApp={false}
      onAfterOpen={() => {}}
      /*      onAfterOpen={() => {
        document.getElementById('input-modal-trade-invalid-note')?.focus()
      }}*/
      onRequestClose={() => {}}
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          minWidth: 600,
          minHeight: 400,
          marginRight: 'auto',
        },
      }}
      contentLabel='Example Modal'
    >
      {isPosting && <AppLoader />}
      {!isPosting && (
        <>
          <h4>Restore trade valid</h4>

          <p>Do you want to restore the following trade as valid?</p>

          {restoreValidSelectTrade !== null && <TradeSummary trade={restoreValidSelectTrade} />}

          <br />

          <button className='btn btn-primary modal-button' onClick={submitRestoreValid}>
            Restore as valid
          </button>

          <button
            className='btn btn-secondary'
            onClick={() => {
              setRestoreValidModalState(false);
              setNoteState('');
            }}
          >
            Cancel
          </button>
        </>
      )}
    </Modal>
  );
};
