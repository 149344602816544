import React from 'react';
import { AFTableLocal } from '../common/table/AFTableLocal';
import { formatDateTimeShipmetrics } from '../common/table/date';

interface Props {
  ports: any[];
}

export const TradeAISTable = (props: Props) => {
  const { ports } = props;

  let i = 0;
  const actualData = ports.reverse().map((el) => {
    i++;
    return {
      ...el,
      No: i,
    };
  });

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'No',
      },
      {
        Header: 'Time',
        accessor: 'MovementDateTime',
        Cell: (cell: any) => <div>{formatDateTimeShipmetrics(cell.value)}</div>,
      },
      {
        Header: 'Latitude',
        accessor: 'Lat',
        Cell: (cell: any) => <div>{Number(cell.value).toFixed(4)}</div>,
      },
      {
        Header: 'Longitude',
        accessor: 'Long',
        Cell: (cell: any) => <div>{Number(cell.value).toFixed(4)}</div>,
      },
      {
        Header: 'DEST (Raw)',
        accessor: 'destination_raw',
      },
      {
        Header: 'DEST (E)',
        accessor: 'destination',
      },
      {
        Header: 'DEST C (E)',
        accessor: 'destinationCountry',
      },
      {
        Header: 'Draught',
        accessor: 'draught',
      },
      {
        Header: 'Speed',
        accessor: 'speed',
      },
      {
        Header: 'ETA',
        accessor: 'eta',
        Cell: (cell: any) => <div>{formatDateTimeShipmetrics(cell.value)}</div>,
      },
      {
        Header: 'Laden (E)',
        accessor: 'laden',
      },
    ],
    [],
  );

  const defaultSortBy = [{ id: 'No', desc: false }];

  return (
    <AFTableLocal data={actualData} columns={columns} initialSortBy={defaultSortBy}></AFTableLocal>
  );
};
