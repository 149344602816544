interface Props {
  value: value;
  options: Option[];
  onChange(value: any): void;
}

type value = string | Date | number;

type Option = {
  label: string;
  value: value;
};

export const RitaPills = (props: Props) => {
  const onChange = (value: value) => props.onChange(value);

  const isActive = (value: value) => props.value === value;

  return (
    <>
      <div
        className="btn-group"
        role="group"
        style={{ zIndex: 0 }}
      >
        {props.options.map((option: Option) => (
          <button
            key={option.value as string}
            type="button"
            className={
              isActive(option.value) ? 'btn btn-light active' : 'btn btn-light'
            }
            onClick={() => {
              onChange(option.value);
            }}
          >
            <strong>{option.label}</strong>
          </button>
        ))}
      </div>
    </>
  );
};
