import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef, useState } from 'react';

import Map, {
  FullscreenControl,
  Layer,
  LayerProps,
  NavigationControl,
  Popup,
  Source,
} from 'react-map-gl';

import { PortsData } from '../services/service';
import { GeoJSON_ports, Position } from './MapContainer';

import { LatLonPopup, PortInfo } from './MapInfoTables';

const mapStyle = 'mapbox://styles/vselyunin/cl5iilhyh000t15p5cwu6t73t';

// Volodymyrs token
const mapToken =
  'pk.eyJ1IjoidnNlbHl1bmluIiwiYSI6ImNsNTQycGJjNjB2OHkzaW15dGZoemdqNGEifQ.luITUdJn_Zqq4PdeCEecqQ';

interface Props {
  children: any;
  allPorts: PortsData[] | undefined;
  newPorts: PortsData[] | undefined;
  addNewPort: Function;
  removeNewPort: Function;
}

const layerOpenSeaStyle: LayerProps = {
  id: 'open-sea',
  type: 'raster',
  paint: {
    'raster-opacity': 0.8,
  },
};

const layerPortsStyle: LayerProps = {
  id: 'ports',
  type: 'fill',
  paint: {
    'fill-color': '#0069D9',
    'fill-opacity': 0.5,
  },
};

const layerNewPortsStyle: LayerProps = {
  id: 'new-ports',
  type: 'fill',
  paint: {
    'fill-color': 'orange',
    'fill-opacity': 0.5,
  },
};

export const PortsMap = (props: Props) => {
  const { children, allPorts, newPorts, addNewPort, removeNewPort } = props;

  const mapRef = useRef<any>(null);

  const [portGeoJSON, setPortGeoJSON] = useState<GeoJSON_ports | undefined>();
  const [newPortsGeoJSON, setNewPortsGeoJSON] = useState<GeoJSON_ports | undefined>();

  const [activePortPopup, setPortPopup] = useState<PortsData | undefined>();
  const [latLon, setLatLon] = useState<any>();

  const getGeoJsonPorts = (ports: PortsData[] | undefined) => {
    if (ports) {
      return {
        type: 'FeatureCollection',
        features: ports.map((p) => ({
          properties: p,
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [p.long1 - 0.05, p.lat1 - 0.05],
                [p.long1 + 0.05, p.lat1 - 0.05],
                [p.long1 + 0.05, p.lat1 + 0.05],
                [p.long1 - 0.05, p.lat1 + 0.05],
                [p.long1 - 0.05, p.lat1 - 0.05],
              ],
            ],
          },
        })),
      } as GeoJSON_ports;
    } else return undefined;
  };

  const getFeaturesAroundPoint = (e: mapboxgl.MapLayerMouseEvent) => {
    const { point } = e;
    const map = mapRef.current.getMap();

    // Find all features within a bounding box around a point
    const width = 10;
    const height = 10;
    const features = map.queryRenderedFeatures(
      [
        [point.x - width / 2, point.y - height / 2],
        [point.x + width / 2, point.y + height / 2],
      ],
      { layers: ['ports', 'new-ports'] },
    );

    return features;
  };

  const showPopup = (e: mapboxgl.MapLayerMouseEvent) => {
    const features = getFeaturesAroundPoint(e);

    //Added timeouts for popups because of https://github.com/visgl/react-map-gl/issues/1727

    if (features?.[0]?.layer?.id === 'ports' || features?.[0]?.layer?.id === 'new-ports') {
      const isNew = features?.[0]?.layer?.id === 'new-ports';
      setTimeout(() => {
        setPortPopup({ ...(features[0].properties as PortsData), isNew });
      }, 0);
      return;
    }

    setTimeout(() => {
      setLatLon(e.lngLat);
    }, 0);
  };

  const addNewPortProxy = (port: any) => {
    setLatLon(undefined);
    addNewPort(port);
  };

  const removeNewPortProxy = (port: any) => {
    setPortPopup(undefined);
    removeNewPort(port);
  };

  const setCursor = (e: mapboxgl.MapLayerMouseEvent) => {
    const features = getFeaturesAroundPoint(e);

    e.target.getCanvas().style.cursor = features.length ? 'pointer' : '';
  };

  useEffect(() => {
    if (allPorts) {
      setPortGeoJSON(getGeoJsonPorts(allPorts));
    }
  }, [allPorts]);

  useEffect(() => {
    if (newPorts) {
      setNewPortsGeoJSON(getGeoJsonPorts(newPorts));
    }
  }, [newPorts]);

  return (
    <Map
      id='map'
      ref={mapRef}
      mapStyle={mapStyle}
      mapboxAccessToken={mapToken}
      initialViewState={{
        longitude: 31,
        latitude: 46,
        zoom: 2,
      }}
      style={{ minHeight: 'calc(100vh - var(--header-bar-size))' }}
      onMouseMove={setCursor}
      onClick={showPopup}
    >
      {children}

      <FullscreenControl />

      <NavigationControl visualizePitch={true} />

      <Source
        type='raster'
        tiles={['https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png']}
        tileSize={256}
        minzoom={0}
        maxzoom={22}
      >
        <Layer beforeId='waterway-label' {...layerOpenSeaStyle} />
      </Source>

      <Source type='geojson' data={portGeoJSON}>
        <Layer {...layerPortsStyle} />
      </Source>

      <Source type='geojson' data={newPortsGeoJSON}>
        <Layer {...layerNewPortsStyle} />
      </Source>

      {activePortPopup && (
        <Popup
          longitude={activePortPopup.long1}
          latitude={activePortPopup.lat1}
          onClose={() => setPortPopup(undefined)}
        >
          <PortInfo port={activePortPopup} removeNewPort={removeNewPortProxy} />
        </Popup>
      )}

      {latLon && (
        <Popup longitude={latLon.lng} latitude={latLon.lat} onClose={() => setLatLon(undefined)}>
          <LatLonPopup latLon={latLon.wrap()} addNewPort={addNewPortProxy} />
        </Popup>
      )}
    </Map>
  );
};
