import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from './MapGoToModal';
import { segment } from '../../state/userinput';
import { getAPIPath } from '../../core';
import { AppLoader } from '../common/AppLoader';
import { useRecoilState } from 'recoil';
import { markInvalidModalState, markInvalidSelectTradeState } from '../../state/rita';
import { TradeWithVisualExtra } from '../../state/trades';
import { toast } from 'react-toastify';
import { getRequestAccessToken } from '../services/service';

export const MarkTradeInvalidModal = () => {
  const [markInvalidModal, setMarkInvalidModalState] = useRecoilState(markInvalidModalState);
  const [markInvalidSelectTrade, setMarkInvalidSelectTrade] = useRecoilState(
    markInvalidSelectTradeState,
  );

  const [segmentVal, setSegment] = useRecoilState(segment);

  const [noteState, setNoteState] = useState('');
  const [isPosting, setIsPosting] = useState(false);

  const submitInvalid = async () => {
    const bodyRaw = {
      arriveDateTimestamp: markInvalidSelectTrade?.arriveDateTimestamp,
      IMO: markInvalidSelectTrade?.Imo,
      note: noteState,
      segment: segmentVal?.value,
    };

    setIsPosting(true);

    try {
      const accessToken = await getRequestAccessToken();

      const headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append('Authorization', bearer);
      headers.append('Content-Type', 'application/json');
      const resp = await fetch(`${getAPIPath()}/api/ship-track/rita/mark-trade`, {
        method: 'POST',
        headers,
        body: JSON.stringify(bodyRaw),
        credentials: 'include',
      });
      setIsPosting(false);

      if (resp.status !== 200) {
        setMarkInvalidModalState(false);
        return toast.error('An error occurred!');
      }

      const data = await resp.json();
      toast.success(
        `Trade ${bodyRaw.IMO}${bodyRaw.arriveDateTimestamp} has been marked as invalid.`,
        {},
      );
      setNoteState('');
      setMarkInvalidModalState(false);

      return data;
    } catch (err) {
      setIsPosting(false);
      setNoteState('');
      return [];
    }
  };

  return (
    <Modal
      isOpen={markInvalidModal}
      ariaHideApp={false}
      onAfterOpen={() => {
        document.getElementById('input-modal-trade-invalid-note')?.focus();
      }}
      onRequestClose={() => {}}
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          minWidth: 600,
          minHeight: 400,
          marginRight: 'auto',
        },
      }}
      contentLabel='Example Modal'
    >
      {isPosting && <AppLoader />}
      {!isPosting && (
        <>
          <h4>Mark trade invalid</h4>

          <p>Do you want to mark the following trade as invalid?</p>

          {markInvalidSelectTrade !== null && <TradeSummary trade={markInvalidSelectTrade} />}

          <strong>Note</strong>
          <textarea
            id='input-modal-trade-invalid-note'
            className='form-control'
            placeholder='Why is the trade invalid?'
            value={noteState}
            onChange={(e) => {
              setNoteState(e.target.value);
            }}
          ></textarea>
          <br />

          <button className='btn btn-primary modal-button' onClick={submitInvalid}>
            Mark as invalid
          </button>

          <button
            className='btn btn-secondary'
            onClick={() => {
              setMarkInvalidModalState(false);
              setNoteState('');
            }}
          >
            Cancel
          </button>
        </>
      )}
    </Modal>
  );
};

interface TradeSummaryProps {
  trade: TradeWithVisualExtra;
}

export const TradeSummary = (props: TradeSummaryProps) => {
  const { Imo, ShipName } = props.trade;

  return (
    <table className='table'>
      <tbody>
        <tr>
          <th>IMO</th>
          <td>{Imo}</td>
        </tr>
        <tr>
          <th>Ship Name</th>
          <td>{ShipName}</td>
        </tr>
      </tbody>
    </table>
  );
};
