export const LoadPort = '#7A017A';
export const LoadPortRoute = '#3A003A';

export const UnloadPort = '#FFA500';
export const UnloadPortRoute = '#DE8F00';

export const DeffaultRoute = '#0854a6';

export const OfficialLoadColor = 'purple';
export const OfficialUnoadColor = 'orange';
export const PortStopLoadColor = '#0BFF00';
export const PortStopColor = '#00AEFF';
