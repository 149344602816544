import { useSetRecoilState } from 'recoil';
import { TradeWithVisualExtra } from '../../state/trades';

import {
  markInvalidModalState,
  markInvalidSelectTradeState,
  restoreValidModalState,
  restoreValidSelectTradeState,
} from '../../state/rita';

export const TableActionsCell = (props: { trade: TradeWithVisualExtra }) => {
  const setMarkInvalidModalState = useSetRecoilState(markInvalidModalState);
  const setMarkInvalidSelectTrade = useSetRecoilState(
    markInvalidSelectTradeState
  );

  const setRestoreValidModalState = useSetRecoilState(restoreValidModalState);
  const setRestoreValidSelectTrade = useSetRecoilState(
    restoreValidSelectTradeState
  );

  const markTradeInvalid = (trade: TradeWithVisualExtra) => {
    setMarkInvalidModalState(true);
    setMarkInvalidSelectTrade(trade);
  };

  const restoreTradeValid = (trade: TradeWithVisualExtra) => {
    setRestoreValidModalState(true);
    setRestoreValidSelectTrade(trade);
  };

  return (
    <div>
      {!!props.trade.auditCreateTime ? (
        <button
          className="btn-sm btn-warning"
          onClick={() => {
            console.dir(props.trade);
            restoreTradeValid(props.trade as TradeWithVisualExtra);
          }}
          title={'Restore'}
        >
          <span className="oi oi-action-undo"></span>
        </button>
      ) : (
        <button
          className="btn-sm btn-danger"
          onClick={() => {
            console.dir(props.trade);
            markTradeInvalid(props.trade as TradeWithVisualExtra);
          }}
          title={'Invalidate'}
        >
          <span className="oi oi-ban"></span>
        </button>
      )}
    </div>
  );
};
