import { useRecoilState } from 'recoil';
import {
  dateFromState,
  dateToState,
  dateUpdatedState,
} from '../../state/userinput';

export const QuickDateSelect = (props: any) => {
  const [dateFrom, setDateFrom] = useRecoilState(dateFromState);
  const [dateTo, setDateTo] = useRecoilState(dateToState);
  const [dateUpdated, setDateUpdated] = useRecoilState(dateUpdatedState);

  const updateTime = (amtDays: number) => {
    const toDate = new Date();
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - amtDays);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setDateUpdated(new Date());
  };

  const isSelected = (amtDays: number) => {
    const toDate = new Date();
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - amtDays);
    return (
      toDate.toDateString() === dateTo.toDateString() &&
      fromDate.toDateString() === dateFrom.toDateString()
    );
  };

  return (
    <div>
      <div
        className="btn-group"
        role="group"
        aria-label="Basic example"
        style={{ zIndex: 0 }}
      >
        <button
          type="button"
          className={isSelected(2) ? 'btn btn-light active' : 'btn btn-light'}
          onClick={() => {
            updateTime(2);
          }}
        >
          <strong>48H</strong>
        </button>
        <button
          type="button"
          className={isSelected(7) ? 'btn btn-light active' : 'btn btn-light'}
          onClick={() => {
            updateTime(7);
          }}
        >
          <strong>7D</strong>
        </button>
        <button
          type="button"
          className={isSelected(31) ? 'btn btn-light active' : 'btn btn-light'}
          onClick={() => {
            updateTime(31);
          }}
        >
          <strong>1M</strong>
        </button>
        <button
          type="button"
          className={isSelected(90) ? 'btn btn-light active' : 'btn btn-light'}
          onClick={() => {
            updateTime(90);
          }}
        >
          <strong>3M</strong>
        </button>
      </div>
    </div>
  );
};
