import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { useRecoilState } from 'recoil';
import { segments } from '../../core';
import { defaultValues } from '../../state/defaultValues';
import { activeVesselState, OptionType, segment } from '../../state/userinput';
import { Vessel } from '../../state/vessels';
import { getVesselListQuery, ParamsTradeSearch } from '../services/service';
import { customStyles } from './MapGoToModal';

interface Props {
  refreshTradeDetails: (updatedValues: Partial<ParamsTradeSearch>) => Promise<void>;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<boolean>;
  refreshPorts: (segment: OptionType | undefined) => Promise<void>;
}

export const SwitchVesselModal = (props: Props) => {
  const { refreshTradeDetails, modalOpen, setModalOpen, refreshPorts } = props;

  const [activeSegment, setActiveSegment] = useRecoilState(segment);
  const [vessels, setVessels] = useState<Vessel[]>([]);
  const [activeVessel, setActiveVessel] = useRecoilState(activeVesselState);

  const [localSegment, setLocalSegment] = useState(activeSegment);
  const [localVessel, setLocalVessel] = useState(activeVessel);

  useEffect(() => {
    setLocalSegment(activeSegment);
    setLocalVessel(activeVessel);
    const fetchVesselsForSegment = async () => {
      const res = await getVesselListQuery(activeSegment);
      if (res) {
        setVessels(res);
      } else return [];
    };
    fetchVesselsForSegment();
  }, []);

  const onSegmentChange = async (value: any) => {
    const res = await getVesselListQuery(value);
    setVessels(res);
    setLocalVessel(defaultValues.activeVessel);
    setLocalSegment(value);
  };

  const doSubmit = async () => {
    setModalOpen(false);
    setActiveSegment(localSegment);
    setActiveVessel(localVessel);
    refreshPorts(localSegment);
    refreshTradeDetails({ activeSegment: localSegment, activeVessel: localVessel });
  };

  return (
    <Modal
      isOpen={modalOpen}
      ariaHideApp={false}
      onAfterOpen={() => {
        document.getElementById('input-modal-vessel')?.focus();
      }}
      onRequestClose={() => {}}
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          minWidth: 400,
          minHeight: 300,
        },
      }}
      contentLabel='Example Modal'
    >
      <h4>Switch vessel</h4>
      <strong>Segment</strong>{' '}
      <Select
        options={segments.map((el) => {
          return {
            value: el.key,
            label: el.name,
          };
        })}
        value={localSegment}
        onChange={onSegmentChange}
      />
      <strong>Vessel</strong>
      <Select
        id='input-modal-vessel'
        options={vessels.map((el) => {
          return {
            value: el.Imo,
            label: `${el.ShipName} (${el.Imo})`,
          };
        })}
        value={localVessel}
        onChange={(value) => {
          setLocalVessel(value as OptionType);
        }}
      />
      <button className='btn btn-primary modal-button mt-3' onClick={doSubmit}>
        Confirm
      </button>
      <button
        className='btn btn-default mt-3'
        onClick={() => {
          setModalOpen(false);
          setLocalSegment(activeSegment);
          setLocalVessel(activeVessel);
        }}
      >
        Close
      </button>
    </Modal>
  );
};
