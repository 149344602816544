import { isDevMode } from './core';
import appSettings from '../src/appSettings.json';

export const msalConfig = {
  auth: {
    clientId: '59ff9420-05ab-4508-9f9c-03dbb3fe9d8b',
    authority: 'https://login.microsoftonline.com/c288c771-c0e2-4e8c-b1ef-7e1ca1e5d216',
    // redirectUri: isDevMode() ? "http://localhost:3000" : 'https://rita.fearnpulse.com',
    redirectUri: isDevMode() ? 'http://localhost:3000' : appSettings.Global.authRedirectUrl,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
