import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  selected: Date;
  onChange(date: Date): void;
  isFirstDatePicker: boolean;
}

export const AFCalendarInput = (props: Props) => {

  const yr = new Date().getFullYear();
  const yrs: number[] = [];
  for (let i = yr; i > yr - 10; i--) {
    yrs.push(i);
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const setDate = (date: Date, e: React.SyntheticEvent<any, Event>) => {
    const dateStringLength = 10; //for date format "dd/mm/yyyy"
    //first case then we click on days in calendar, second value of input
    if (e.currentTarget.innerHTML || e.currentTarget.value.toString().length === dateStringLength) {
      props.onChange(date);
    }
  };

  return (
  <div style={{'maxWidth':'113px'}}>
      <ReactDatePicker
        popperPlacement={props.isFirstDatePicker ? 'bottom-start' : 'bottom-end'}
       className='form-control'
        dateFormat='dd/MM/yyyy'
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
          className='datePickerControls'
          style={{
            margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button className='btn' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {'◀'}
            </button>
            <select
              className='btn'
              value={
                date.getFullYear()
                // year
              }
              onChange={({ target: { value } }) => {
                changeYear(Number(value));
              }}
              >
              {(yrs as any[]).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className='btn'
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
  
            <button className='btn' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {'▶'}
            </button>
          </div>
        )}
        selected={props.selected}
        onChange={setDate}
      />
        </div>
    );
    
};
