import moment from 'moment';
import { VesselTrade } from '../../state/map';
import { Trade } from '../../state/trades';
import { InputMode } from '../../state/userinput';
import { TradeDetailsTable } from '../common/TradeDetailsTable';
import { TradeAISTable } from './TradeAISTable';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';

interface Props {
  inputMode: InputMode;
  activeTrade: Trade | undefined;
  tradeDetails: VesselTrade | undefined;
}

export const MapDetails = (props: Props) => {
  const { inputMode, activeTrade, tradeDetails } = props;

  return (
    <>
      {inputMode === InputMode.singleTrip && (
        <div className='container-fluid'>
          <Tabs selectedTabClassName='trade-details-caption_active'>
            <TabList className='nav trade-details-caption'>
              <Tab className='nav-item nav-link'>Ais</Tab>

              <Tab className='nav-item nav-link'>Portstops</Tab>
            </TabList>
            <div style={{ paddingBottom: '20px' }}>
              <span>
                <span style={{ fontWeight: 500 }}>Vessel: </span>
                <span>
                  {activeTrade?.ShipName} ({activeTrade?.Imo})
                </span>
              </span>
              <span style={{ marginLeft: '30px' }}>
                <span style={{ fontWeight: 500 }}>Active trade: </span>
                <span>
                  {moment(activeTrade?.arriveDateTimestamp).format('DD.MM.yyyy HH:mm:ss')}
                </span>
              </span>
            </div>
            <TabPanel>
              {tradeDetails && <TradeAISTable ports={tradeDetails.shipmetrics} />}
            </TabPanel>
            <TabPanel>
              {tradeDetails && <TradeDetailsTable ports={tradeDetails.portStops} />}
            </TabPanel>
          </Tabs>
        </div>
      )}
    </>
  );
};
