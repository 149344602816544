import { OptionType } from './userinput';
import { isDevMode } from '../core';

const getDefaultFromDate = (): Date => {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d;
};

const defaultFillActive = true; // Adjust this to enable / disable prefiller
const isActive = defaultFillActive && isDevMode();

export const defaultValues = {
    activeVessel: isActive
        ? ( { value: '9259680', label: '9259680' } as OptionType | null)
        : (null as OptionType | null),
    segment: isActive
        ? ({ value: 'panamax', label: 'panamax' } as OptionType | undefined)
        : (undefined as OptionType | undefined), // (undefined) as OptionType|undefined
    dateFrom: isActive ? new Date('2020-04-01T08:52:21.000Z') : getDefaultFromDate(), // getDefaultFromDate()
    dateTo: isActive ? new Date('2020-07-01T08:52:21.000Z') : new Date(), // new Date()
};
