import { useEffect, useState } from 'react';
import { MapProvider } from 'react-map-gl';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PortStop, Shipmetric, VesselTrade } from '../../state/map';
import { getSavedMapData } from '../../state/presetsStorage';
import { activeTradeState } from '../../state/trades';
import {
  activeVesselState,
  dateFromState,
  dateToState,
  InputMode,
  inputModeState,
  leadTimeState,
  OptionType,
  segment,
} from '../../state/userinput';
import { DateRangePicker } from '../common/DateRangePicker';
import { MapAdjustLeadModal } from '../modals/MapAdjustLeadModal';
import { MapGoToModal } from '../modals/MapGoToModal';
import { SwitchVesselModal } from '../modals/SwitchVesselModal';
import {
  getPorts,
  addPort,
  removePort,
  getVesselsTradeDetails,
  ParamsTradeSearch,
  PortsData,
  getNewPorts,
} from '../services/service';
import { MapControlPanel } from './MapControlPanel';
import { MapDetails } from './MapDetails';
import { MapInfo } from './MapInfo';
import { MapGL as Map } from './MapRMGL';

export enum MapMode {
  move,
  addPort,
}

export interface Position {
  lng: number;
  lat: number;
}

export interface PortStopPointProperties extends PortStop {
  isLoad?: boolean;
  isUnload?: boolean;
  aIsLoad?: boolean;
  bIsLoad?: boolean;
  isOfficialLoad?: boolean;
  isOfficialUnload?: boolean;
  title?: string;
  color?: string;
}

export interface PortStopsPoint {
  properties: PortStopPointProperties;
  coordinates: number[];
}

export type GeoJSONLineShipmetrics = GeoJSON.FeatureCollection<GeoJSON.LineString>;
export type GeoJSONPointsShipmetrics = GeoJSON.FeatureCollection<GeoJSON.Point, Shipmetric>;
export type GeoJSONPortStopsShipmetrics = GeoJSON.FeatureCollection<
  GeoJSON.Point,
  PortStopPointProperties
>;

export type GeoJSON_ports = GeoJSON.FeatureCollection<GeoJSON.Polygon, PortsData>;

export const MapContainer = () => {
  const [tradeDetails, setTradeDetails] = useState<VesselTrade | undefined>();
  const [allPorts, setAllPorts] = useState<PortsData[] | undefined>();
  const [newPorts, setNewPorts] = useState<PortsData[] | undefined>();

  const [dateFrom, setDateFrom] = useRecoilState(dateFromState);
  const [dateTo, setDateTo] = useRecoilState(dateToState);
  const activeTrade = useRecoilValue(activeTradeState);
  const leadTime = useRecoilValue(leadTimeState);
  const inputMode = useRecoilValue(inputModeState);
  const [activeSegment] = useRecoilState(segment);
  const [activeVessel] = useRecoilState(activeVesselState);

  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [adjustLeadModalOpen, setAdjustLeadModalOpen] = useState(false);
  const [switchVesselModalOpen, setSwitchVesselModalOpen] = useState(false);
  const [currentMapPos, setCurrentMapPos] = useState<Position>({ lng: 0, lat: 0 });

  const [height, setHeight] = useState(40);

  const paramsTradeSearch = {
    dateFrom,
    dateTo,
    activeSegment,
    activeVessel,
    inputMode,
    activeTrade,
    leadTime,
  };

  const refreshTradeDetails = async (updatedValues: Partial<ParamsTradeSearch>) => {
    setIsLoading(true);
    const tradeData = await getVesselsTradeDetails({ ...paramsTradeSearch, ...updatedValues });
    console.log('trade details res', tradeData);
    setTradeDetails(tradeData);
    setIsLoading(false);
  };

  const refreshPorts = async (segment: OptionType | undefined) => {
    const ports = await getPorts(segment);
    const newPorts = await getNewPorts(segment);

    setAllPorts(ports);
    setNewPorts(newPorts);
  };

  const setDateRange = async (value: any) => {
    const { dateFrom, dateTo } = value;
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    refreshTradeDetails({ dateFrom, dateTo });
  };

  useEffect(() => {
    const previousData = getSavedMapData();
    if (previousData) {
      refreshPorts(previousData.activeSegment);
      refreshTradeDetails({ ...previousData });
    } else {
      refreshPorts(activeSegment);
      refreshTradeDetails({});
    }
  }, []);

  useEffect(() => {
    !!activeTrade ? setHeight(40) : setHeight(100);
  }, [activeTrade]);

  return (
    <>
      <MapProvider>
        <Map
          height={height}
          allPorts={allPorts}
          newPorts={newPorts}
          tradeDetails={tradeDetails}
          setCurrentMapPos={setCurrentMapPos}
        >
          <MapInfo
            activeSegment={activeSegment}
            activeVessel={activeVessel}
            inputMode={inputMode}
            leadTime={leadTime}
            currentMapPos={currentMapPos}
            isLoading={isLoading}
          />
          <MapControlPanel
            setModalOpen={setModalOpen}
            setSwitchVesselModalOpen={setSwitchVesselModalOpen}
            setAdjustLeadModalOpen={setAdjustLeadModalOpen}
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateRange={setDateRange}
            setHeight={!!activeTrade ? setHeight : undefined}
            height={!!activeTrade ? height : undefined}
          />
        </Map>

        <MapGoToModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setCurrentMapPos={setCurrentMapPos}
        />

        <MapAdjustLeadModal
          refreshTradeDetails={refreshTradeDetails}
          modalOpen={adjustLeadModalOpen}
          setModalOpen={setAdjustLeadModalOpen}
        />
        <SwitchVesselModal
          refreshTradeDetails={refreshTradeDetails}
          modalOpen={switchVesselModalOpen}
          setModalOpen={setSwitchVesselModalOpen}
          refreshPorts={refreshPorts}
        />

        <MapDetails inputMode={inputMode} activeTrade={activeTrade} tradeDetails={tradeDetails} />
      </MapProvider>
    </>
  );
};
