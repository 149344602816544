import { useState } from 'react';
import { useMap } from 'react-map-gl';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Position } from '../Map/MapContainer';

interface Props {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  setCurrentMapPos: React.Dispatch<React.SetStateAction<Position>>;
}

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: 400,
    // minHeight: 600,
  },
  overlay: { zIndex: 1000 },
};

export const MapGoToModal = (props: Props) => {
  const { modalOpen, setModalOpen, setCurrentMapPos } = props;
  const { map } = useMap();
  const [lat, setLat] = useState<string>('');
  const [lng, setLng] = useState<string>('');

  const doSubmit = () => {
    if (lat.trim() === '' || lng.trim() === '') {
      toast.warning('Empty inputs, make your choice');
      return;
    }
    const latitude = Number(lat.replace(/,/g, '.'));
    const longitude = Number(lng.replace(/,/g, '.'));
    if (Math.abs(latitude) <= 90 && Math.abs(longitude) <= 180) {
      setModalOpen(false);
      map && map.easeTo({ center: [longitude, latitude] });
      setCurrentMapPos({ lat: latitude, lng: longitude });
    } else toast.error('Incorrect latitude or longitude value');
    setLat('');
    setLng('');
  };

  return (
    <Modal
      isOpen={modalOpen}
      ariaHideApp={false}
      onAfterOpen={() => {
        document.getElementById('input-modal-jmp-latitude')?.focus();
      }}
      onRequestClose={() => {}}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <h4>Jump to</h4>

      <div className='row'>
        <div className='col-sm'>
          <h6>Lat</h6>
          <input
            id='input-modal-jmp-latitude'
            type='text'
            className='form-control'
            placeholder='Latitude'
            value={lat}
            onChange={(e) => {
              setLat(e.target.value);
            }}
            onKeyUp={(key) => {
              if (key.key === 'Enter') doSubmit();
            }}
          />
        </div>
        <div className='col-sm'>
          <h6>Long</h6>
          <input
            type='text'
            className='form-control'
            placeholder='Longitude'
            value={lng}
            onChange={(e) => {
              setLng(e.target.value);
            }}
            onKeyUp={(key) => {
              if (key.key === 'Enter') doSubmit();
            }}
          />
        </div>
      </div>

      <button className='btn btn-primary modal-button mt-3' onClick={doSubmit}>
        Confirm
      </button>

      <button
        className='btn btn-default mt-3'
        onClick={() => {
          setModalOpen(false);
          setLat('');
          setLng('');
        }}
      >
        Close
      </button>
    </Modal>
  );
};
