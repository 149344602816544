import { useEffect, useState } from 'react';
import { MapProvider } from 'react-map-gl';
import { useRecoilState } from 'recoil';
import Select from 'react-select';
import { getSavedMapData } from '../../state/presetsStorage';
import { OptionType, segment, activeVesselState } from '../../state/userinput';
import { segments } from '../../core';
import { MapGoToModal } from '../modals/MapGoToModal';
import { defaultValues } from '../../state/defaultValues';
import { getPorts, addPort, removePort, PortsData, getNewPorts } from '../services/service';
import { PortsMap as Map } from './PortsMap';
import { useAccount } from '@azure/msal-react';

export enum MapMode {
  move,
  addPort,
}

export interface Position {
  lng: number;
  lat: number;
}

export type GeoJSON_ports = GeoJSON.FeatureCollection<GeoJSON.Polygon, PortsData>;

export const PortsMapContainer = () => {
  const [allPorts, setAllPorts] = useState<PortsData[] | undefined>();
  const [newPorts, setNewPorts] = useState<PortsData[] | undefined>();

  const [activeSegment, setActiveSegment] = useRecoilState(segment);
  const [activeVessel, setActiveVessel] = useRecoilState(activeVesselState);

  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentMapPos, setCurrentMapPos] = useState<Position>({ lng: 0, lat: 0 });

  const accountInfo = useAccount();

  const refreshPorts = async (segment: OptionType | undefined) => {
    const ports = await getPorts(segment);
    const newPorts = await getNewPorts(segment);

    setAllPorts(ports);
    setNewPorts(newPorts);
  };

  const addNewPort = async (port: any) => {
    const user = accountInfo?.name || 'unknown';

    const res = newPorts?.concat({
      ...port,
      createdBy: user,
      createdAt: new Date().toString(),
    });
    const { lat1, long1, destination, region, country } = port;
    await addPort(lat1, long1, destination, region, country, activeSegment?.value as string, user);
    setNewPorts(res);
  };

  const removeNewPort = async (port: any) => {
    const res = newPorts?.filter((item) => item.lat1 !== port.lat1 || item.long1 !== port.long1);

    await removePort(port.lat1, port.long1, activeSegment?.value as string);
    setNewPorts(res);
  };

  const onSegmentChange = async (value: any) => {
    setIsLoading(true);
    setActiveSegment(value);
    setActiveVessel(defaultValues.activeVessel);
    refreshPorts(value);
    setIsLoading(false);
  };

  useEffect(() => {
    const previousData = getSavedMapData();
    if (previousData) {
      refreshPorts(previousData.activeSegment);
    } else {
      refreshPorts(activeSegment);
    }
  }, []);

  return (
    <>
      <MapProvider>
        <Map
          allPorts={allPorts}
          newPorts={newPorts}
          addNewPort={addNewPort}
          removeNewPort={removeNewPort}
        >
          <div
            style={{
              zIndex: 2,
              paddingLeft: 10,
            }}
          >
            <div style={{ position: 'relative', paddingTop: 10, maxWidth: '200px' }}>
              <div>
                <h6 style={{ fontWeight: 'bold' }}>Segment</h6>
                <Select
                  options={segments.map((el) => {
                    return {
                      value: el.key,
                      label: el.name,
                    };
                  })}
                  value={activeSegment}
                  onChange={onSegmentChange}
                />
              </div>
            </div>

            <br />
            <div className='btn-group-vertical mt-2' role='group' style={{ zIndex: 0 }}>
              <button
                type='button'
                title='Jump to'
                className={'btn btn-secondary border-dark p-1'}
                style={{ minWidth: 35 }}
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                <span className='oi oi-target'></span>
              </button>
            </div>
          </div>
        </Map>
        <MapGoToModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setCurrentMapPos={setCurrentMapPos}
        />
      </MapProvider>
    </>
  );
};
