import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import {
  OfficialLoadColor,
  OfficialUnoadColor,
  PortStopColor,
  PortStopLoadColor,
} from '../../colors';
import { PortStop, VesselTrade } from '../../state/map';
import {
  clusterPortStopsCountLayer,
  clusterRoutePointCountLayer,
  layerNewPorts,
  layerOpenSea,
  layerPorts,
  layerPortStops,
  layerPortStopsCluster,
  layerRoute,
  layerRouteArrowHeads,
  layerRoutePoint,
  layerRoutePointCluster,
} from './MapLayers';

import Map, {
  FullscreenControl,
  GeoJSONSource,
  Layer,
  NavigationControl,
  Popup,
  Source,
  useMap,
} from 'react-map-gl';

import { Shipmetric } from '../../state/map';
import { PortsData } from '../services/service';
import {
  GeoJSONLineShipmetrics,
  GeoJSONPointsShipmetrics,
  GeoJSONPortStopsShipmetrics,
  GeoJSON_ports,
  PortStopsPoint,
  Position,
} from './MapContainer';

import { LatLonPopup, PortInfo, PortStopsInfo, ShipmetricInfo } from './MapInfoTables';

import { useRecoilValue } from 'recoil';

import { activeTradeState } from '../../state/trades';

const mapStyle = 'mapbox://styles/vselyunin/cl5iilhyh000t15p5cwu6t73t';

// Volodymyrs token
const mapToken =
  'pk.eyJ1IjoidnNlbHl1bmluIiwiYSI6ImNsNTQycGJjNjB2OHkzaW15dGZoemdqNGEifQ.luITUdJn_Zqq4PdeCEecqQ';

// Andriys token
// const mapToken =
//   'pk.eyJ1IjoiYXpoZWJlbGlldiIsImEiOiJjbDRvbWx2NnMwNjV3M2xtd3N3MnEzMWl1In0.SNzlDY0PGhFNELe9ZRwBrA';

// Propduction tokens
// const mapStyle = 'mapbox://styles/mapbox/light-v10';
// const mapToken =
//   'pk.eyJ1IjoiaG9nbmUiLCJhIjoiY2t0MTkwbDZnMDg0OTJwczJrdmdzdGJrayJ9.i_va34LRIQrIfmoF6FM2HA';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  height: number;
  allPorts: PortsData[] | undefined;
  newPorts: PortsData[] | undefined;
  tradeDetails: VesselTrade | undefined;
  setCurrentMapPos: React.Dispatch<React.SetStateAction<Position>>;
}

export const MapGL = (props: Props) => {
  const { children, allPorts, newPorts, tradeDetails, setCurrentMapPos, height } = props;

  const mapRef = useRef<any>(null);

  const [portGeoJSON, setPortGeoJSON] = useState<GeoJSON_ports | undefined>();
  const [newPortsGeoJSON, setNewPortsGeoJSON] = useState<GeoJSON_ports | undefined>();
  const [routeGeoJSON, setRouteGeoJSON] = useState<GeoJSONLineShipmetrics | undefined>();
  const [portStopsGeoJson, setPortStopsGeoJson] = useState<
    GeoJSONPortStopsShipmetrics | undefined
  >();
  const [routePointGeoJSON, setRoutePointGeoJSON] = useState<
    GeoJSONPointsShipmetrics | undefined
  >();

  const [activePortPopup, setPortPopup] = useState<PortsData | undefined>();
  const [activeShipmetric, setActiveShipmetric] = useState<Shipmetric>();
  const [activePortStop, setActivePortStop] = useState<any>();
  const [latLon, setLatLon] = useState<any>();

  const activeTrade = useRecoilValue(activeTradeState);

  const ifCrossAntimeridian = (
    previousPosition: number[][],
    currentPosition: number[],
    currentIndex: number,
  ) => {
    if (!!previousPosition[currentIndex - 1]) {
      const previousPos = [...previousPosition[currentIndex - 1]];

      if (currentPosition[0] - previousPos[0] >= 180) {
        previousPosition.push([currentPosition[0] - 360, currentPosition[1]]);
      } else if (currentPosition[0] - previousPos[0] < -180) {
        previousPosition.push([currentPosition[0] + 360, currentPosition[1]]);
      } else {
        previousPosition.push(currentPosition);
      }
    } else {
      previousPosition.push(currentPosition);
    }

    return previousPosition;
  };

  const getGeoJsonRoute = (shipmetrics: Shipmetric[], portStops: PortStop[]) => {
    if (shipmetrics) {
      shipmetrics = [...shipmetrics].sort(
        (a, b) => new Date(a.MovementDateTime).valueOf() - new Date(b.MovementDateTime).valueOf(),
      );
      let mainRouteCoordinates = shipmetrics
        .filter((shipmetric) => !shipmetric.isLead)
        .map((shipmetric) => [Number(shipmetric.Long), Number(shipmetric.Lat)]);

      const preRouteCoordinates = shipmetrics
        .filter((shipmetric) => shipmetric.isLeadPre)
        .map((shipmetric) => [Number(shipmetric.Long), Number(shipmetric.Lat)]);

      const postRouteCoordinates = shipmetrics
        .filter((shipmetric) => shipmetric.isLeadPost)
        .map((shipmetric) => [Number(shipmetric.Long), Number(shipmetric.Lat)]);

      const loadingPort = getPortStopsPoints(portStops).find(
        (port) => port.properties.title === 'Trade loading',
      )?.coordinates;
      const unLoadingPort = getPortStopsPoints(portStops).find(
        (port) => port.properties.title === 'Trade unloading',
      )?.coordinates;

      if (loadingPort) mainRouteCoordinates = [loadingPort, ...mainRouteCoordinates];
      if (unLoadingPort) mainRouteCoordinates = [...mainRouteCoordinates, unLoadingPort];

      const route: GeoJSONLineShipmetrics = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              type: 'main',
            },
            geometry: {
              type: 'LineString',
              coordinates: mainRouteCoordinates.reduce(ifCrossAntimeridian, []),
            },
          },
          {
            type: 'Feature',
            properties: {
              type: 'pre',
            },
            geometry: {
              type: 'LineString',
              coordinates: !!mainRouteCoordinates.length
                ? [...preRouteCoordinates, mainRouteCoordinates[0]].reduce(ifCrossAntimeridian, [])
                : preRouteCoordinates.reduce(ifCrossAntimeridian, []),
            },
          },
          {
            type: 'Feature',
            properties: {
              type: 'post',
            },
            geometry: {
              type: 'LineString',
              coordinates: !!mainRouteCoordinates.length
                ? [
                    mainRouteCoordinates[mainRouteCoordinates.length - 1],
                    ...postRouteCoordinates,
                  ].reduce(ifCrossAntimeridian, [])
                : postRouteCoordinates.reduce(ifCrossAntimeridian, []),
            },
          },
        ],
      };

      return route;
    }

    return undefined;
  };

  const getGeoJsonRoutePoints = (shipmetrics: Shipmetric[]) => {
    if (shipmetrics) {
      const routePoints: GeoJSONPointsShipmetrics = {
        type: 'FeatureCollection',
        features: shipmetrics.map((movement) => ({
          type: 'Feature',
          properties: movement,
          geometry: {
            type: 'Point',
            coordinates: [Number(movement.Long), Number(movement.Lat)],
          },
        })),
      };

      return routePoints;
    }

    return undefined;
  };

  const getTradeLoadPoints = (portStops: PortStop[]) =>
    portStops
      .filter((ps) => !!ps.FromPortA && !!ps.loadPort && !!ps.ALon1 && !!ps.ALat1)
      .map((ps) => {
        const aIsLoad = ps.FromPortA.toLowerCase() === ps.loadPort.toLowerCase();

        return !!activeTrade?.loadPort && ps.FromPortA.toLowerCase() === ps?.loadPort.toLowerCase()
          ? {
              properties: {
                ...ps,
                isLoad: true,
                isOfficialLoad: true,
                title: 'Trade loading',
                color: OfficialLoadColor,
              },
              coordinates: [Number(ps.ALon1), Number(ps.ALat1)],
            }
          : {
              properties: {
                ...ps,
                aIsLoad,
                isLoad: true,
                title: aIsLoad ? 'Portstop Load' : 'Portstop',
                color: aIsLoad ? PortStopLoadColor : PortStopColor,
              },
              coordinates: [Number(ps.ALon1), Number(ps.ALat1)],
            };
      });

  const getTradeUnloadPoints = (portStops: PortStop[]) =>
    portStops
      .filter((ps) => !!ps.ToPortB && !!ps.loadPort && !!ps.BLon1 && !!ps.BLat1)
      .map((ps) => {
        const bIsLoad = ps.loadPort.toLowerCase() === ps.ToPortB.toLowerCase();

        return !!activeTrade?.loadPort && ps.FromPortA.toLowerCase() === ps?.loadPort.toLowerCase()
          ? {
              properties: {
                ...ps,
                isUnload: true,
                isOfficialUnload: true,
                title: 'Trade unloading',
                color: OfficialUnoadColor,
              },
              coordinates: [Number(ps.BLon1), Number(ps.BLat1)],
            }
          : {
              properties: {
                ...ps,
                bIsLoad,
                isUnload: true,
                title: bIsLoad ? 'Portstop Load' : 'Portstop',
                color: bIsLoad ? PortStopLoadColor : PortStopColor,
              },
              coordinates: [Number(ps.BLon1), Number(ps.BLat1)],
            };
      });

  const fixDuplicatedCoordinates = (portStopsPoints: PortStopsPoint[]) => {
    const uniqueJSONCoordinates = new Set(
      portStopsPoints.map((point: PortStopsPoint) => JSON.stringify(point.coordinates)),
    );

    const duplicatedPortStopsPoints: PortStopsPoint[][] = [];
    const uniquePortStopsPoints: PortStopsPoint[] = [];

    uniqueJSONCoordinates.forEach((coordinatesJSON: string) => {
      const portStopsPointsByCoordinates = portStopsPoints.filter(
        (el2: PortStopsPoint) => coordinatesJSON === JSON.stringify(el2.coordinates),
      );

      if (portStopsPointsByCoordinates.length === 1)
        uniquePortStopsPoints.push(portStopsPointsByCoordinates[0]);
      else duplicatedPortStopsPoints.push(portStopsPointsByCoordinates);
    });

    duplicatedPortStopsPoints.forEach((portStopsPoints: PortStopsPoint[]) => {
      const pointsAmount = portStopsPoints.length;
      const baseCoordinates = portStopsPoints[0].coordinates;

      const points = turf.randomPoint(pointsAmount, {
        bbox: [
          baseCoordinates[0] - 0.025,
          baseCoordinates[1] - 0.025,
          baseCoordinates[0] + 0.025,
          baseCoordinates[1] + 0.025,
        ],
      });

      const coordinates = turf.coordAll(points);

      uniquePortStopsPoints.push(
        ...portStopsPoints.map((portStopsPoint: PortStopsPoint, index: number) => ({
          ...portStopsPoint,
          coordinates: coordinates[index],
        })),
      );
    });

    return uniquePortStopsPoints;
  };

  const getPortStopsPoints = (portStops: PortStop[]) =>
    fixDuplicatedCoordinates([
      ...getTradeLoadPoints(portStops),
      ...getTradeUnloadPoints(portStops),
    ]);

  const toPortStopsPointGeoJSON = (portStopsPoint: PortStopsPoint) => {
    return turf.point(portStopsPoint.coordinates, portStopsPoint.properties);
  };

  const getPortStopsPointsGeoJSON = (portStops: PortStop[]) => {
    return turf.featureCollection(getPortStopsPoints(portStops).map(toPortStopsPointGeoJSON));
  };

  const getGeoJsonPorts = (ports: PortsData[] | undefined) => {
    if (ports) {
      return {
        type: 'FeatureCollection',
        features: ports.map((p) => ({
          properties: p,
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [p.long1 - 0.05, p.lat1 - 0.05],
                [p.long1 + 0.05, p.lat1 - 0.05],
                [p.long1 + 0.05, p.lat1 + 0.05],
                [p.long1 - 0.05, p.lat1 + 0.05],
                [p.long1 - 0.05, p.lat1 - 0.05],
              ],
            ],
          },
        })),
      } as GeoJSON_ports;
    } else return undefined;
  };

  const getFeaturesAroundPoint = (e: mapboxgl.MapLayerMouseEvent) => {
    const { point } = e;
    const map = mapRef.current.getMap();

    // Find all features within a bounding box around a point
    const width = 10;
    const height = 10;
    const features = map.queryRenderedFeatures(
      [
        [point.x - width / 2, point.y - height / 2],
        [point.x + width / 2, point.y + height / 2],
      ],
      {
        layers: [
          layerPorts.id,
          layerRoutePoint.id,
          layerPortStops.id,
          layerNewPorts.id,
          layerRoutePointCluster.id,
          layerPortStopsCluster.id,
        ],
      },
    );

    return features;
  };

  const showPopup = (e: mapboxgl.MapLayerMouseEvent) => {
    const features = getFeaturesAroundPoint(e);

    const feature = features?.[0];
    const clusterId = feature?.properties?.cluster_id;

    const portStopsSource = mapRef.current.getSource('port-stops-source') as GeoJSONSource;
    const routePointSource = mapRef.current.getSource('route-point-source') as GeoJSONSource;

    //Added timeouts for popups because of https://github.com/visgl/react-map-gl/issues/1727

    if (features?.[0]?.layer?.id === 'ports' || features?.[0]?.layer?.id === 'new-ports') {
      const isNew = features?.[0]?.layer?.id === 'new-ports';
      setTimeout(() => {
        setPortPopup({ ...(features[0].properties as PortsData), isNew });
      }, 0);
      return;
    }

    if (features?.[0]?.layer?.id === 'route-point') {
      console.log('route popup');
      setTimeout(() => {
        setActiveShipmetric(features[0].properties as Shipmetric);
      }, 0);
      return;
    }

    if (features?.[0]?.layer?.id === 'route-port-stop') {
      setTimeout(() => {
        setActivePortStop({
          properties: features?.[0]?.properties,
          coordinates: features?.[0]?.geometry.coordinates,
        });
      }, 0);

      return;
    }

    if (!!clusterId) {
      portStopsSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err && feature?.geometry?.coordinates) {
          console.log('error popup');
          return;
        }

        mapRef.current.easeTo({
          center: feature?.geometry?.coordinates,
          zoom,
          duration: 500,
        });
      });

      routePointSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err && feature?.geometry?.coordinates) {
          console.log('error 1 popup');
          return;
        }

        mapRef.current.easeTo({
          center: feature?.geometry?.coordinates,
          zoom,
          duration: 500,
        });
      });

      return;
    }
    setTimeout(() => {
      setLatLon(e.lngLat);
    }, 0);
  };

  const setCursor = (e: mapboxgl.MapLayerMouseEvent) => {
    const features = getFeaturesAroundPoint(e);

    e.target.getCanvas().style.cursor = features.length ? 'pointer' : '';
  };

  const setCenter = () => {
    if (tradeDetails) {
      let coordinates = tradeDetails.shipmetrics.map((e) => [e.Long, e.Lat]);
      if (coordinates.length < 2) return;

      const center = turf.centroid({ coordinates: [coordinates], type: 'Polygon' });

      map?.flyTo({
        center: [center.geometry.coordinates[0], center.geometry.coordinates[1]],
        speed: 2,
        maxDuration: 3000,
      });
      setCurrentMapPos({
        lng: center.geometry.coordinates[0],
        lat: center.geometry.coordinates[1],
      });
    }
  };

  const { map } = useMap();

  const onload = () => {
    setCenter();
  };

  useEffect(() => {
    if (allPorts) {
      setPortGeoJSON(getGeoJsonPorts(allPorts));
    }
  }, [allPorts]);

  useEffect(() => {
    if (newPorts) {
      setNewPortsGeoJSON(getGeoJsonPorts(newPorts));
    }
  }, [newPorts]);

  useEffect(() => {
    setCenter();
    if (tradeDetails) {
      setRouteGeoJSON(getGeoJsonRoute(tradeDetails.shipmetrics, tradeDetails.portStops));
      setRoutePointGeoJSON(getGeoJsonRoutePoints(tradeDetails.shipmetrics));
      setPortStopsGeoJson(getPortStopsPointsGeoJSON(tradeDetails.portStops));
    }
  }, [tradeDetails]);

  useEffect(() => {
    if (!mapRef?.current && !!height) return;

    mapRef.current.resize();
  }, [height]);
  console.log('route data', routeGeoJSON, tradeDetails);
  return (
    <Map
      id='map'
      ref={mapRef}
      mapStyle={mapStyle}
      mapboxAccessToken={mapToken}
      initialViewState={{
        longitude: 31,
        latitude: 46,
        zoom: 2,
      }}
      style={{ minHeight: `calc(${height}vh - var(--header-bar-size))` }}
      onMouseMove={setCursor}
      onClick={showPopup}
      onLoad={onload}
    >
      {children}

      <FullscreenControl />

      <NavigationControl visualizePitch={true} />

      <Source
        type='raster'
        tiles={['https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png']}
        tileSize={256}
        minzoom={0}
        maxzoom={22}
      >
        <Layer beforeId='waterway-label' {...layerOpenSea} />
      </Source>

      <Source type='geojson' data={portGeoJSON}>
        <Layer {...layerPorts} />
      </Source>

      <Source type='geojson' data={newPortsGeoJSON}>
        <Layer {...layerNewPorts} />
      </Source>

      <Source type='geojson' data={routeGeoJSON}>
        <Layer {...layerRoute} />
        <Layer {...layerRouteArrowHeads} />
      </Source>

      <Source
        id='port-stops-source'
        data={portStopsGeoJson}
        cluster={true}
        clusterMaxZoom={6}
        clusterRadius={30}
        type='geojson'
      >
        <Layer {...layerPortStopsCluster} />
        <Layer {...clusterPortStopsCountLayer} />
        <Layer {...layerPortStops} />
      </Source>

      <Source
        id='route-point-source'
        cluster={true}
        clusterMaxZoom={6}
        clusterRadius={30}
        type='geojson'
        data={routePointGeoJSON}
      >
        <Layer {...layerRoutePointCluster} />
        <Layer {...clusterRoutePointCountLayer} />
        <Layer {...layerRoutePoint} />
      </Source>

      {activePortPopup && (
        <Popup
          longitude={activePortPopup.long1}
          latitude={activePortPopup.lat1}
          onClose={() => setPortPopup(undefined)}
        >
          <PortInfo port={activePortPopup} />
        </Popup>
      )}

      {activeShipmetric && (
        <Popup
          longitude={activeShipmetric.Long}
          latitude={activeShipmetric.Lat}
          onClose={() => setActiveShipmetric(undefined)}
          maxWidth='none'
        >
          <ShipmetricInfo shipmetric={activeShipmetric} />
        </Popup>
      )}

      {activePortStop && (
        <Popup
          longitude={activePortStop.coordinates[0]}
          latitude={activePortStop.coordinates[1]}
          onClose={() => setActivePortStop(undefined)}
          maxWidth='none'
        >
          <PortStopsInfo activePortStop={activePortStop.properties} />
        </Popup>
      )}

      {latLon && (
        <Popup longitude={latLon.lng} latitude={latLon.lat} onClose={() => setLatLon(undefined)}>
          <LatLonPopup latLon={latLon.wrap()} />
        </Popup>
      )}
    </Map>
  );
};
