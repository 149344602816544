import { useState } from 'react';
import Modal from 'react-modal';
import { useRecoilState } from 'recoil';
import { leadTimeState } from '../../state/userinput';
import { ParamsTradeSearch } from '../services/service';
import { customStyles } from './MapGoToModal';

interface Props {
  refreshTradeDetails: (updatedValues: Partial<ParamsTradeSearch>) => Promise<void>;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<boolean>;
}

export const MapAdjustLeadModal = (props: Props) => {
  const { refreshTradeDetails, modalOpen, setModalOpen } = props;

  const [leadTime, setLeadTime] = useRecoilState(leadTimeState);

  const [localLeadtime, setLocalLeadTime] = useState(leadTime);

  const doSubmit = async () => {
    setModalOpen(false);
    setLeadTime(localLeadtime);
    await refreshTradeDetails({ leadTime: localLeadtime });
  };

  return (
    <Modal
      isOpen={modalOpen}
      ariaHideApp={false}
      onAfterOpen={() => {
        document.getElementById('input-modal-lead-time')?.focus();
      }}
      onRequestClose={() => {}}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <h4>Adjust lead time</h4>

      <div className='row'>
        <div className='col-sm'>
          <h6>Lead time ±</h6>
          <input
            id='input-modal-lead-time'
            type='text'
            className='form-control'
            placeholder='±'
            value={localLeadtime}
            onChange={(e) => {
              if (isNaN(Number(e.target.value))) return;
              setLocalLeadTime(Number(e.target.value));
            }}
            onKeyUp={(key) => {
              if (key.key === 'Enter') doSubmit();
            }}
          />
        </div>
      </div>

      <button className='btn btn-primary mt-3' onClick={doSubmit}>
        Confirm
      </button>

      <button
        className='btn btn-default mt-3'
        onClick={() => {
          setLocalLeadTime(leadTime);
          setModalOpen(false);
        }}
      >
        Close
      </button>
    </Modal>
  );
};
