import { useEffect, useState } from 'react';
import { AFCalendarInput } from './AFCalendarInput';
import './DateRangePicker.css';

interface DateRange {
  dateFrom: Date;
  dateTo: Date;
}

interface Props {
  from?: Date;
  to?: Date;
  onChange(value: DateRange): void;
}

export const DateRangePicker = (props: Props) => {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  useEffect(() => {
    props.from && setDateFrom(props.from);
    props.to && setDateTo(props.to);
  }, [props.from, props.to]);

  return (
    <>
      <div className='dateInputContainer'>
        <div className='dateInput'>
          <AFCalendarInput
          isFirstDatePicker={true}
            onChange={(date) => {
              setDateFrom(date as Date);
              props.onChange({ dateFrom: date, dateTo });
            }}
            selected={dateFrom}
          />
        </div>
        <div style={{ margin: 'auto 10px', color: '#007BFF' }}>{'❱❱❱'}</div>
        <div className='dateInput'>
          <AFCalendarInput
          isFirstDatePicker={false}
            onChange={(date) => {
              setDateTo(date as Date);
              props.onChange({ dateFrom, dateTo: date });
            }}
            selected={dateTo}
          />
        </div>
      </div>
    </>
  );
};

export type { DateRange };
