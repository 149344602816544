import { useMsal } from '@azure/msal-react';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { UrlPaths } from '../core';

interface Props {
  children: ReactNode;
}

export const Theme = (props: any) => {
  const { instance, accounts, inProgress } = useMsal();

  const classActive = 'nav-link active';
  const classInactive = 'nav-link';

  function handleLogout(instance: any) {
    instance.logoutRedirect().catch((e: any) => {
      console.error(e);
    });
  }

  return (
    <div>
      <nav className='navbar navbar-expand navbar-light mb-1'>
        <div className='container-fluid'>
          <NavLink
            className='navbar-brand'
            to='/'
            style={{
              fontFamily: 'Monoton',
              fontSize: '1.8em',
              marginRight: '25px',
              color: '#007BFF',
              textShadow: '1px 1px 1px grey',
              padding: '0',
            }}
          >
            R.I.T.A
          </NavLink>
          <div className='collapse navbar-collapse ' id='navbarNav'>
            <ul className='nav nav-tabs'>
              <li className='nav-item'>
                <NavLink
                  exact={true}
                  to={UrlPaths.TRACK_TRADE}
                  className={(isActive) => (isActive ? classActive : classInactive)}
                  style={(isActive) =>
                    isActive
                      ? {
                          color: '#007BFF',
                          textTransform: 'uppercase',
                          textShadow: '1px 1px 1px grey',
                        }
                      : { color: 'gray' }
                  }
                >
                  Trade Search
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  exact={true}
                  to={UrlPaths.INSPECT_TRADES}
                  className={(isActive) => (isActive ? classActive : classInactive)}
                  style={(isActive) =>
                    isActive
                      ? {
                          color: '#007BFF',
                          textTransform: 'uppercase',
                          textShadow: '1px 1px 1px grey',
                        }
                      : { color: 'gray' }
                  }
                >
                  Latest Trades
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  exact={true}
                  to={UrlPaths.PORT_UPDATES}
                  className={(isActive) => (isActive ? classActive : classInactive)}
                  style={(isActive) =>
                    isActive
                      ? {
                          color: '#007BFF',
                          textTransform: 'uppercase',
                          textShadow: '1px 1px 1px grey',
                        }
                      : { color: 'gray' }
                  }
                >
                  Port Updates
                </NavLink>
              </li>
            </ul>
          </div>
          <button className='btn btn-outline-primary ml-2' onClick={() => handleLogout(instance)}>
            Sign out
          </button>
        </div>
      </nav>

      <div id='page-content-wrapper'>
        <div className='container-fluid' style={{ padding: 0 }}>
          {props.children}
        </div>
      </div>
    </div>
  );
};
