import { atom, RecoilState } from 'recoil';
import { Trade } from './trades';
import { TradeWithVisualExtra } from './trades';

const markInvalidModalState: RecoilState<boolean> = atom({
  key: 'markInvalidModalState',
  default: false as boolean,
});

const markInvalidSelectTradeState: RecoilState<TradeWithVisualExtra | null> =
  atom({
    key: 'markInvalidSelectTradeState',
    default: null as TradeWithVisualExtra | null,
  });

const restoreValidModalState: RecoilState<boolean> = atom({
  key: 'restoreValidModalState',
  default: false as boolean,
});

const restoreValidSelectTradeState: RecoilState<TradeWithVisualExtra | null> =
  atom({
    key: 'restoreValidSelectTradeState',
    default: null as TradeWithVisualExtra | null,
  });

const openTradeModalState: RecoilState<boolean> = atom({
  key: 'openTradeModalState',
  default: false as boolean,
});

const openTradeSelectTradeState: RecoilState<TradeWithVisualExtra | null> =
  atom({
    key: 'openTradeSelectTradeState',
    default: null as TradeWithVisualExtra | null,
  });

export {
  markInvalidModalState,
  markInvalidSelectTradeState,
  restoreValidModalState,
  restoreValidSelectTradeState,
  openTradeModalState,
  openTradeSelectTradeState,
};

export type { Trade };
