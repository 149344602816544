import { useEffect, useState } from 'react';
import Select from 'react-select';
import { BarLoader } from 'react-spinners';
import { useRecoilState, useRecoilValue } from 'recoil';

import { segments } from '../../core';
import {
  activeVesselState,
  dateFromState,
  dateToState,
  OptionType,
  segment,
} from '../../state/userinput';

import { getTradesBySegment } from '../services/service';

import { DateRange, DateRangePicker } from '../common/DateRangePicker';
import { QuickDateSelect } from '../common/QuickDateSelect';
import { RitaPills } from '../common/RitaPills';
import { AFTable } from '../common/table/AFTable';

import { MarkTradeInvalidModal } from '../modals/MarkTradeInvalidModal';
import { OpenTradeModal } from '../modals/OpenTradeModal';
import { RestoreTradeValidModal } from '../modals/RestoreTradeValidModal';

import { defaultValues } from '../../state/defaultValues';
import { getLastSavedValue } from '../../state/presetsStorage';
import { markInvalidModalState, restoreValidModalState } from '../../state/rita';
import { SortBy } from '../common/types';
import { columns } from './TradesColumns';

enum TradesState {
  IsValid = 'valid',
  InAudit = 'invalid',
  All = 'all',
}

const tradeSelectOptions = [
  { value: TradesState.IsValid, label: 'Valid trades' },
  { value: TradesState.InAudit, label: 'Audit trades' },
  { value: TradesState.All, label: 'All trades' },
];

const defaultSortBy = [
  { id: 'Imo', desc: true },
  { id: 'departDate', desc: false },
];

export const LatestTrades = () => {
  const [activeSegment, setSegment] = useRecoilState(segment);
  const [, setActiveVessel] = useRecoilState(activeVesselState);

  const [dateFrom, setDateFrom] = useRecoilState(dateFromState);
  const [dateTo, setDateTo] = useRecoilState(dateToState);

  const [tradesState, setTradesState] = useState<string>(TradesState.IsValid);

  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState(defaultSortBy);

  const [searching, setSearching] = useState(true);
  const [loading, setLoading] = useState(false);

  const auditMode = tradesState !== TradesState.IsValid;

  const invalidateModalState = useRecoilValue(markInvalidModalState);
  const restoreModalState = useRecoilValue(restoreValidModalState);

  const storedSegment = getLastSavedValue('activeSegment') as OptionType | undefined;

  const setDateRange = (value: DateRange) => {
    setDateFrom(value.dateFrom);
    setDateTo(value.dateTo);
  };

  const resetTable = () => {
    setPage(1);
    setSortBy(defaultSortBy);
  };

  const fetchData = async (
    segment: string,
    tradesState: string,
    dateFrom: Date,
    dateTo: Date,
    page: number,
    perPage: number,
    sortBy: SortBy[],
  ) => {
    console.log('sortBy', sortBy);
    const response = await getTradesBySegment(
      segment,
      tradesState,
      dateFrom,
      dateTo,
      page,
      perPage,
      sortBy,
    );

    setData(response.trades);
    setTotalPages(response.pagination.lastPage);
    setLoading(false);
    setSearching(false);
  };

  useEffect(() => {
    if (!activeSegment) return;

    setLoading(true);
    resetTable();
    if (storedSegment) {
      fetchData(storedSegment.value, tradesState, dateFrom, dateTo, 1, perPage, defaultSortBy);
    } else fetchData(activeSegment.value, tradesState, dateFrom, dateTo, 1, perPage, defaultSortBy);
  }, [activeSegment, tradesState, dateFrom, dateTo]);

  useEffect(() => {
    if (!activeSegment) return;

    if (storedSegment) {
      fetchData(storedSegment.value, tradesState, dateFrom, dateTo, page, perPage, sortBy);
    } else fetchData(activeSegment.value, tradesState, dateFrom, dateTo, page, perPage, sortBy);
  }, [page, perPage, sortBy, invalidateModalState, restoreModalState]);

  return (
    <>
      <MarkTradeInvalidModal />
      <RestoreTradeValidModal />
      <OpenTradeModal />

      <div className='jumbotron row'>
        <div className='col-xl-3 col-md-4 '>
          <h3>Segment</h3>
          <Select
            options={segments.map((el) => {
              return {
                value: el.key,
                label: el.name,
              };
            })}
            value={activeSegment}
            onChange={(value) => {
              setSegment(value as OptionType);
              setActiveVessel(defaultValues.activeVessel);
            }}
          />
        </div>
        <div className='col-xl-6 col-md-8'>
          <h3>Date from/to</h3>
          <div className='d-flex'>
            <div className='mr-3 '>
              <QuickDateSelect />
            </div>
            <DateRangePicker
              from={dateFrom}
              to={dateTo}
              onChange={(value) => setDateRange(value)}
            />
          </div>
        </div>
        <div className='col-12'>
          <hr />
          <RitaPills options={tradeSelectOptions} value={tradesState} onChange={setTradesState} />
        </div>
      </div>

      <div className='rita-table-container'>
        <BarLoader
          loading={loading}
          height={4}
          width={200}
          cssOverride={{
            display: 'block',
            margin: 'auto',
          }}
        />

        {!searching && !loading && (
          <AFTable
            data={data}
            columns={columns(auditMode)}
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={page}
            perPage={perPage}
            totalPage={totalPages}
            initialSortBy={sortBy}
            onChangeSort={setSortBy}
          ></AFTable>
        )}
      </div>
    </>
  );
};
