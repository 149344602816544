import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { BarLoader } from 'react-spinners';

import { Trade } from '../../state/trades';
import { segment, activeVesselState, dateFromState, dateToState } from '../../state/userinput';
import { markInvalidModalState, restoreValidModalState } from '../../state/rita';

import { getTradesBySegmentAndVessel } from '../services/service';
import { TableMapLinkCell } from './TableMapLinkCell';
import { AFTable } from '../common/table/AFTable';
import { AFTableDateCell } from '../common/table/AFTableDateCell';
import { TableActionsCell } from '../LatestTrades/TableActionsCell';
import { AFTableDateTimeCell } from '../common/table/AFTableDateTimeCell';

import { MarkTradeInvalidModal } from '../modals/MarkTradeInvalidModal';
import { RestoreTradeValidModal } from '../modals/RestoreTradeValidModal';

import { columns as tradeColumns } from '../LatestTrades/TradesColumns';

import { SortBy } from '../common/types';

const defaultSortBy = [
  { id: 'Imo', desc: true },
  { id: 'departDate', desc: false },
];

export const TradeList = (props: {}) => {
  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState(defaultSortBy);

  const [searching, setSearching] = useState(true);
  const [loading, setLoading] = useState(false);

  const activeSegment = useRecoilValue(segment);
  const activeVessel = useRecoilValue(activeVesselState);
  const dateFrom = useRecoilValue(dateFromState);
  const dateTo = useRecoilValue(dateToState);

  const invalidateModalState = useRecoilValue(markInvalidModalState);
  const restoreModalState = useRecoilValue(restoreValidModalState);

  const resetTable = () => {
    setPage(1);
    setSortBy(defaultSortBy);
  };

  const columns = useMemo(() => [...tradeColumns(true)], []);

  const fetchData = async (
    segment: string,
    vessel: string,
    dateFrom: Date,
    dateTo: Date,
    page: number,
    perPage: number,
    sortBy: SortBy[],
  ) => {
    const response = await getTradesBySegmentAndVessel(
      segment,
      vessel,
      dateFrom,
      dateTo,
      page,
      perPage,
      sortBy,
    );

    setData(response.data);
    setTotalPages(response.pagination.lastPage);
    setLoading(false);
    setSearching(false);
  };

  useEffect(() => {
    if (!activeSegment) return;
    if (!activeVessel) return;

    setLoading(true);
    resetTable();
    fetchData(activeSegment.value, activeVessel.value, dateFrom, dateTo, 1, perPage, defaultSortBy);
  }, [activeSegment, activeVessel, dateFrom, dateTo]);

  useEffect(() => {
    if (!activeSegment) return;
    if (!activeVessel) return;

    fetchData(activeSegment.value, activeVessel.value, dateFrom, dateTo, page, perPage, sortBy);
  }, [page, perPage, sortBy, invalidateModalState, restoreModalState]);

  return (
    <div className='rita-table-container'>
      <MarkTradeInvalidModal />
      <RestoreTradeValidModal />
      <BarLoader
        loading={loading}
        height={4}
        width={200}
        cssOverride={{
          display: 'block',
          margin: 'auto',
        }}
      />

      {!searching && !loading && (
        <AFTable
          data={data}
          columns={columns}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
        ></AFTable>
      )}
    </div>
  );
};
