import { LayerProps } from 'react-map-gl';
import { DeffaultRoute, LoadPortRoute, UnloadPortRoute } from '../../colors';

export const layerOpenSea: LayerProps = {
  id: 'open-sea',
  type: 'raster',
  paint: {
    'raster-opacity': 0.8,
  },
};

export const layerPorts: LayerProps = {
  id: 'ports',
  type: 'fill',
  paint: {
    'fill-color': '#0069D9',
    'fill-opacity': 0.5,
  },
};

export const layerNewPorts: LayerProps = {
  id: 'new-ports',
  type: 'fill',
  paint: {
    'fill-color': 'orange',
    'fill-opacity': 0.5,
  },
};

export const layerRouteArrowHeads: LayerProps = {
  id: 'route-arrow-head',
  type: 'symbol',
  layout: {
    'symbol-placement': 'line',
    'icon-image': [
      'match',
      ['get', 'type'],
      'pre',
      'triangle-FFA500',
      'post',
      'triangle-DE8F00',
      'triangle',
    ],
    'icon-size': 0.6,
    'icon-rotate': 90,
    'icon-rotation-alignment': 'auto',
  },
};

export const layerRoute: LayerProps = {
  id: 'route',
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': [
      'match',
      ['get', 'type'],
      'pre',
      LoadPortRoute,
      'post',
      UnloadPortRoute,
      DeffaultRoute,
    ],
    'line-width': 1,
    'line-dasharray': [
      'match',
      ['get', 'type'],
      'pre',
      ['literal', [3.5, 3.5]],
      'post',
      ['literal', [3.5, 3.5]],
      ['literal', [1]],
    ],
  },
};

export const clusterRoutePointCountLayer: LayerProps = {
  id: 'cluster-count-route-point',
  type: 'symbol',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 14,
  },
  paint: {
    'text-color': '#fff',
  },
};

export const clusterPortStopsCountLayer: LayerProps = {
  id: 'cluster-count-port-stops',
  type: 'symbol',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 14,
  },
  paint: {
    'text-color': '#fff',
  },
};

export const layerRoutePointCluster: LayerProps = {
  id: 'route-point-cluster',
  type: 'circle',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': DeffaultRoute,
    'circle-radius': ['step', ['get', 'point_count'], 15, 10, 20, 750, 30],
    'circle-opacity': 0.8,
    'circle-stroke-width': 5,
    'circle-stroke-color': DeffaultRoute,
    'circle-stroke-opacity': 0.3,
  },
};

export const layerRoutePoint: LayerProps = {
  id: 'route-point',
  type: 'circle',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': [
      'case',
      ['boolean', ['get', 'isLeadPre'], true],
      LoadPortRoute,
      ['boolean', ['get', 'isLeadPost'], true],
      UnloadPortRoute,
      DeffaultRoute,
    ],
    'circle-radius': 5,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff',
  },
};

export const layerPortStopsCluster: LayerProps = {
  id: 'route-port-stop-cluster',
  type: 'circle',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': UnloadPortRoute,
    'circle-radius': ['step', ['get', 'point_count'], 15, 10, 20, 750, 30],
    'circle-opacity': 0.8,
    'circle-stroke-width': 5,
    'circle-stroke-color': UnloadPortRoute,
    'circle-stroke-opacity': 0.4,
  },
};

export const layerPortStops: LayerProps = {
  id: 'route-port-stop',
  type: 'circle',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': ['get', 'color'],
    'circle-radius': [
      'case',
      ['==', ['get', 'isOfficialLoad'], true],
      ['literal', 6],
      ['==', ['get', 'isOfficialUnload'], true],
      ['literal', 6],
      ['literal', 5],
    ],
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff',
    'circle-opacity': 0.9,
  },
};
