import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { LoggedInRoot } from './components/LoggedInRoot';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { FirstPage } from './components/common/FirstPage/FirstPageLanding';
import { useEffect } from 'react';

function App() {
  return (
    <RecoilRoot>
      <Router>
        <>
          <MainApp />
          <ToastContainer />
        </>
      </Router>
    </RecoilRoot>
  );
}

function MainApp() {
  const { instance, inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && inProgress === 'none') {
      const msalAccounts = instance.getAllAccounts();
      console.log('instance.getAllAccounts()', instance.getAllAccounts());
      instance.setActiveAccount(msalAccounts[0]);
    }
  }, [isAuthenticated, inProgress, instance]);

  async function handleLogin() {
    await instance.loginRedirect();
  }

  console.log('inprogress', inProgress);

  // Check authentication
  if (!isAuthenticated) {
    return (
      <FirstPage
        handleLogin={() => {
          handleLogin();
        }}
      />
    );
  }

  if (isAuthenticated && inProgress === 'none') {
    return <LoggedInRoot />;
  }

  return <></>;
}

export default App;
