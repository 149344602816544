import React from 'react';
import { useState } from 'react';
import { AFTable } from '../common/table/AFTable';

interface Props {
  ports: any[];
}

export const TradeDetailsTable = (props: Props) => {
  const { ports } = props;

  let i = 0;
  const actualData = ports.reverse().map((el) => {
    i++;
    return {
      ...el,
      No: i,
    };
  });

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'No',
      },
      {
        Header: 'FromPortA',
        accessor: 'FromPortA',
      },
      {
        Header: 'ToPortB',
        accessor: 'ToPortB',
      },
      {
        Header: 'LoadAtB',
        accessor: 'LoadAtB',
      },
      {
        Header: 'UnloadAtB',
        accessor: 'UnloadAtB',
      },
      {
        Header: 'DraughtChangeB',
        accessor: 'DraughtChangeB',
      },
      {
        Header: 'FromCountry',
        accessor: 'FromCountry',
      },
      {
        Header: 'ToCountry',
        accessor: 'ToCountry',
      },
      {
        Header: 'EstimatedDischargePct',
        accessor: 'EstimatedDischargePct',
      },
    ],
    [],
  );

  const defaultSortBy = [{ id: 'No', desc: true }];
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState(defaultSortBy);

  return (
    <AFTable
      data={actualData}
      columns={columns}
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={page}
      perPage={perPage}
      totalPage={totalPages}
      initialSortBy={sortBy}
      onChangeSort={setSortBy}
    ></AFTable>
  );
};
