import { useState } from 'react';
import Modal from 'react-modal';
import { Shipmetric } from '../../state/map';
import { formatDateTimeShipmetrics } from '../common/table/date';
import { customStyles } from '../modals/MapGoToModal';
import { PortsData } from '../services/service';

export const LatLonPopup = (props: { latLon: any; addNewPort?: Function }) => {
  const { latLon, addNewPort } = props;
  const [openNewPortModal, setOpenNewPortModal] = useState<any>();
  const [region, setRegion] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [destination, setDestination] = useState<string>();

  const lat = Number(latLon.lat.toFixed(1));
  const lng = Number(latLon.lng.toFixed(1));

  return (
    <>
      <table className='table-sm table-striped' style={{ marginTop: '15px' }}>
        <tbody>
          <tr>
            <td>Latitude</td>
            <td>
              <strong>{latLon.lat.toFixed(2)}</strong>
            </td>
          </tr>
          <tr>
            <td>Longitude</td>
            <td>
              <strong>{latLon.lng.toFixed(2)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        {addNewPort && (
          <button
            className='btn btn-outline-primary btn-sm'
            style={{ width: '100%' }}
            onClick={() => {
              setOpenNewPortModal(true);
            }}
          >
            Add port
          </button>
        )}

        {addNewPort && (
          <Modal
            isOpen={openNewPortModal}
            ariaHideApp={false}
            onAfterOpen={() => {}}
            onRequestClose={() => {}}
            style={{
              ...customStyles,
              content: {
                ...customStyles.content,
                marginRight: 'auto',
              },
            }}
            contentLabel='Add New Port'
          >
            <>
              <h4>Add new port square</h4>

              <div className='row'>
                <label style={{ fontWeight: 500 }} className='col-sm-4 col-form-label'>
                  Latitude
                </label>
                <div className='col'>
                  <label className='col-form-label'>{Number(latLon.lat).toFixed(1)}</label>
                </div>
              </div>

              <div className='row'>
                <label style={{ fontWeight: 500 }} className='col-sm-4 col-form-label'>
                  Longtitude
                </label>
                <div className='col'>
                  <label className='col-form-label'>{Number(latLon.lng).toFixed(1)}</label>
                </div>
              </div>

              <div className='row'>
                <label style={{ fontWeight: 500 }} className='col-sm-4 col-form-label'>
                  Region
                </label>
                <div className='col col-sm-8'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='region'
                    placeholder='Region'
                    value={region}
                    onChange={(event) => {
                      setRegion(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className='row'>
                <label style={{ fontWeight: 500 }} className='col-sm-4 col-form-label'>
                  Country
                </label>
                <div className='col col-sm-8'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='country'
                    placeholder='Country'
                    value={country}
                    onChange={(event) => {
                      setCountry(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className='row'>
                <label style={{ fontWeight: 500 }} className='col-sm-4 col-form-label'>
                  Destination
                </label>
                <div className='col col-sm-8'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='destination'
                    placeholder='Destination'
                    value={destination}
                    onChange={(event) => {
                      setDestination(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col'>
                  <hr></hr>

                  <button
                    className='btn btn-primary modal-button'
                    onClick={() => {
                      addNewPort({
                        country: country,
                        destination: destination,
                        lat1: lat,
                        long1: lng,
                        region: region,
                      });
                      setOpenNewPortModal(false);
                    }}
                  >
                    Save
                  </button>

                  <button
                    className='btn btn-secondary'
                    onClick={() => {
                      setOpenNewPortModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          </Modal>
        )}
      </div>
    </>
  );
};

export const ShipmetricInfo = (props: { shipmetric: Shipmetric }) => {
  const { shipmetric } = props;

  return (
    <table className='table-sm table-striped '>
      <tbody>
        <tr>
          <td>Time</td>
          <td>
            <strong>{formatDateTimeShipmetrics(shipmetric.MovementDateTime)}</strong>
          </td>
        </tr>
        <tr>
          <td>Latitude</td>
          <td>
            <strong>{Number(shipmetric.Lat).toFixed(4)}</strong>
          </td>
        </tr>
        <tr>
          <td>Longitude</td>
          <td>
            <strong>{Number(shipmetric.Long).toFixed(4)}</strong>
          </td>
        </tr>
        <tr>
          <td>DEST (Raw)</td>
          <td>
            <strong>{shipmetric.destination_raw}</strong>
          </td>
        </tr>
        <tr>
          <td>DEST (E)</td>
          <td>
            <strong>{shipmetric.destination}</strong>
          </td>
        </tr>
        <tr>
          <td>DEST C (E)</td>
          <td>
            <strong>{shipmetric.destinationCountry}</strong>
          </td>
        </tr>
        <tr>
          <td>Draught</td>
          <td>
            <strong>{shipmetric.draught}</strong>
          </td>
        </tr>
        <tr>
          <td>Speed</td>
          <td>
            <strong>{shipmetric.speed}</strong>
          </td>
        </tr>
        <tr>
          <td>ETA</td>
          <td>
            <strong>{formatDateTimeShipmetrics(shipmetric.eta)}</strong>
          </td>
        </tr>
        <tr>
          <td>Laden (E)</td>
          <td>
            <strong>{shipmetric.laden}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export const PortInfo = (props: { port: PortsData; removeNewPort?: Function }) => {
  const { port, removeNewPort } = props;
  console.log('port', port);
  return (
    <>
      <h4 className='mt-2 text-center'>{port.destination}</h4>
      <table className='table-sm table-striped '>
        <tbody>
          <tr>
            <td>Latitude</td>
            <td>
              <strong>{Number(port.lat1).toFixed(1)}</strong>
            </td>
          </tr>
          <tr>
            <td>Longitude</td>
            <td>
              <strong>{Number(port.long1).toFixed(1)}</strong>
            </td>
          </tr>
          <tr>
            <td>Region</td>
            <td>
              <strong>{port.region}</strong>
            </td>
          </tr>
          <tr>
            <td>Country</td>
            <td>
              <strong>{port.country}</strong>
            </td>
          </tr>
          {port.createdAt && (
            <tr>
              <td>Created At</td>
              <td>
                <strong>{new Date(port.createdAt).toLocaleString()}</strong>
              </td>
            </tr>
          )}
          {port.createdBy && (
            <tr>
              <td>Created By</td>
              <td>
                <strong>{port.createdBy}</strong>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {port.isNew && removeNewPort && (
        <div>
          <button
            className='btn btn-outline-primary btn-sm'
            style={{ width: '100%' }}
            onClick={() => {
              removeNewPort(port);
            }}
          >
            Remove port
          </button>
        </div>
      )}
    </>
  );
};

export const PortStopsInfo = (props: { activePortStop: any }) => {
  const { activePortStop } = props;

  return (
    <>
      <h4 className='mt-2 text-center'>{activePortStop.title}</h4>
      <table className='table-sm table-striped '>
        {activePortStop.isLoad && (
          <tbody>
            <tr>
              <td>Latitude</td>
              <td>
                <strong>{Number(activePortStop.ALat1).toFixed(1)}</strong>
              </td>
            </tr>
            <tr>
              <td>Longitude</td>
              <td>
                <strong>{Number(activePortStop.ALon1).toFixed(1)}</strong>
              </td>
            </tr>
            <tr>
              <td>Arrive A</td>
              <td>
                <strong>{formatDateTimeShipmetrics(activePortStop.ArriveATimestamp)}</strong>
              </td>
            </tr>
            <tr>
              <td>Depart A</td>
              <td>
                <strong>{formatDateTimeShipmetrics(activePortStop.DepartATimestamp)}</strong>
              </td>
            </tr>
            <tr>
              <td>Port</td>
              <td>
                <strong>{activePortStop.FromPortA}</strong>
              </td>
            </tr>
          </tbody>
        )}

        {activePortStop.isUnload && (
          <tbody>
            <tr>
              <td>Latitude</td>
              <td>
                <strong>{Number(activePortStop.BLat1).toFixed(1)}</strong>
              </td>
            </tr>
            <tr>
              <td>Longitude</td>
              <td>
                <strong>{Number(activePortStop.BLon1).toFixed(1)}</strong>
              </td>
            </tr>
            <tr>
              <td>Arrive B</td>
              <td>
                <strong>{formatDateTimeShipmetrics(activePortStop.ArriveBTimestamp)}</strong>
              </td>
            </tr>
            <tr>
              <td>Depart B</td>
              <td>
                <strong>{formatDateTimeShipmetrics(activePortStop.DepartBTimestamp)}</strong>
              </td>
            </tr>
            <tr>
              <td>EstimatedDischargePct</td>
              <td>
                <strong>{activePortStop.EstimatedDischargePct}</strong>
              </td>
            </tr>
            <tr>
              <td>Port</td>
              <td>
                <strong>{activePortStop.ToPortB}</strong>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </>
  );
};
