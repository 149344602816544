import { atom, RecoilState } from 'recoil';
import { savePresets } from './presetsStorage';

interface Trade {
  departDate: string;
  departDateTimestamp: string;
  loadCountry: string;
  loadRegion: string;
  loadPort: string;
  loadDraughtChange: string;
  discharge: string;
  discharges: string;
  arriveDate: string;
  arriveDateTimestamp: string;
  dischargeCountry: string;
  dischargePort: string;
  dischargeRegion: string;
  ShipName: string;
  Imo: string;
  EstimatedDischargePct: string;
  ArriveDraughtB: string;
  DraughtChangeB: string;
  certainty: string;
  TonMiles: string;
  cabotage: string;
  auditCreateTime: string;
  note: string;
}

interface TradeWithVisualExtra extends Trade {
  departPortCountry: string;
  arrivePortCountry: string;
}

const activeTradeState: RecoilState<Trade | undefined> = atom({
  key: 'activeTradeState', // unique ID (with respect to other atoms/selectors)
  default: undefined as Trade | undefined, // default value (aka initial value)
  effects: [
    ({ onSet }) => {
      onSet((newID) => {
        savePresets(newID, 'activeTrade');
      });
    },
  ],
});

export { activeTradeState };
export type { Trade, TradeWithVisualExtra };
