import { useMap } from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import { ReactComponent as Ship } from '../../Images/ship.svg';
import { InputMode, inputModeState } from '../../state/userinput';
import { DateRange, DateRangePicker } from '../common/DateRangePicker';

interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAdjustLeadModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSwitchVesselModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dateFrom: Date;
  dateTo: Date;
  setDateRange: (value: DateRange) => void;
  setHeight?: React.Dispatch<React.SetStateAction<number>>;
  height?: number;
}

export const MapControlPanel = (props: Props) => {
  const {
    setModalOpen,
    setSwitchVesselModalOpen,
    setAdjustLeadModalOpen,
    setDateRange,
    setHeight,
    height,
    dateFrom,
    dateTo,
  } = props;
  const inputMode = useRecoilValue(inputModeState);
  const { map } = useMap();

  const current = map?.getMap();

  const visibleClassName = 'btn btn-sm btn-secondary';
  const invisibleClassName = 'btn btn-sm btn-secondary disabled';
  const changeLayerVisibility = (
    layers: string[],
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (current) {
      const className = e.currentTarget.className;
      if (className === visibleClassName) {
        e.currentTarget.className = invisibleClassName;
      } else e.currentTarget.className = visibleClassName;

      layers.forEach((layer) => {
        if (className === visibleClassName) {
          current.setLayoutProperty(layer, 'visibility', 'none');
        } else {
          current.setLayoutProperty(layer, 'visibility', 'visible');
        }
      });
    }
  };

  return (
    <>
      <div
        style={{
          zIndex: 2,
          paddingLeft: 10,
        }}
      >
        <div style={{ position: 'relative', paddingTop: 10, maxWidth: '270px' }}>
          {inputMode === InputMode.trackVessel && (
            <DateRangePicker
              from={dateFrom}
              to={dateTo}
              onChange={(value) => setDateRange(value)}
            />
          )}
        </div>
        <div className='btn-group mt-2'>
          <button
            className={visibleClassName}
            onClick={(e) => changeLayerVisibility(['ports', 'new-ports'], e)}
          >
            ports
          </button>
          <button
            className={visibleClassName}
            onClick={(e) => changeLayerVisibility(['route', 'route-arrow-head'], e)}
          >
            routes
          </button>
          <button
            className={visibleClassName}
            onClick={(e) =>
              changeLayerVisibility(
                ['route-point', 'cluster-count-route-point', 'route-point-cluster'],
                e,
              )
            }
          >
            points
          </button>
          <button
            className={visibleClassName}
            onClick={(e) =>
              changeLayerVisibility(
                ['route-port-stop', 'cluster-count-port-stops', 'route-port-stop-cluster'],
                e,
              )
            }
          >
            stops
          </button>
        </div>
        <br />
        <div className='btn-group-vertical mt-2' role='group' style={{ zIndex: 0 }}>
          {inputMode === InputMode.trackVessel && (
            <button
              type='button'
              title='Switch vessel'
              className={'btn btn-secondary border-dark p-1'}
              onClick={() => {
                setSwitchVesselModalOpen(true);
              }}
            >
              <Ship style={{ fill: 'white', width: 25, height: 25 }} />
            </button>
          )}
          {/* next button is commented but it is requested not to be fully deleted (in future could be used) */}
          {/* <button
          type="button"
          className={
            mapMode === MapMode.addPort
              ? 'btn btn-secondary active mapToolBtn'
              : 'btn btn-secondary mapToolBtn'
            }
            onClick={() => {
              setMapMode(MapMode.addPort);
            }}
        >
        <span className="oi oi-pin"></span> Add port
      </button> */}
          <button
            type='button'
            title='Jump to'
            className={'btn btn-secondary border-dark p-1'}
            style={{ minWidth: 35 }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <span className='oi oi-target'></span>
          </button>
          <button
            type='button'
            title='Adjust lead time'
            className={'btn btn-secondary border-dark p-1'}
            onClick={() => {
              setAdjustLeadModalOpen(true);
            }}
          >
            <span className='oi oi-resize-width'></span>
          </button>

          {setHeight && height === 100 && (
            <button
              type='button'
              title='Minimize'
              className={'btn btn-secondary border-dark p-1'}
              onClick={() => {
                setHeight(40);
              }}
            >
              <span className='oi oi-arrow-top'></span>
            </button>
          )}

          {setHeight && height === 40 && (
            <button
              type='button'
              title='Maximize'
              className={'btn btn-secondary border-dark p-1'}
              onClick={() => {
                setHeight(100);
              }}
            >
              <span className='oi oi-arrow-bottom'></span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};
