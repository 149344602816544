import { useSetRecoilState } from 'recoil';
import { TradeWithVisualExtra } from '../../state/trades';

import {
  openTradeModalState,
  openTradeSelectTradeState,
} from '../../state/rita';

export const TableTradeLinkCell = (props: {
  trade: TradeWithVisualExtra;
  value: any;
}) => {
  const setOpenTradeModal = useSetRecoilState(openTradeModalState);
  const setOpenTradeSelectTrade = useSetRecoilState(openTradeSelectTradeState);

  const openTrip = (trade: TradeWithVisualExtra) => {
    setOpenTradeModal(true);
    setOpenTradeSelectTrade(trade);
  };

  return (
    <div>
      <a
        href="#"
        onClick={(e) => {
          e.stopPropagation();
          openTrip(props.trade);
        }}
      >
        {props.value}
      </a>
    </div>
  );
};
