import { atom, RecoilState } from 'recoil';
import { defaultValues } from './defaultValues';
import { savePresets } from './presetsStorage';

const segment: RecoilState<OptionType | undefined> = atom({
  key: 'SegmentState', // unique ID (with respect to other atoms/selectors)
  default: defaultValues.segment, // default value (aka initial value)
  effects: [
    ({ onSet }) => {
      onSet((newID) => {
        savePresets(newID, 'activeSegment');
      });
    },
  ],
});

type OptionType = {
  value: string;
  label: string;
};

const activeVesselState: RecoilState<OptionType | null> = atom({
  key: 'ActiveVesselState', // unique ID (with respect to other atoms/selectors)
  default: defaultValues.activeVessel, // default value (aka initial value)
  effects: [
    ({ onSet }) => {
      onSet((newID) => {
        savePresets(newID, 'activeVessel');
      });
    },
  ],
});

enum InputMode {
  selectTrip,
  singleTrip,
  trackVessel,
}

const inputModeState: RecoilState<InputMode> = atom({
  key: 'InputModeState', // unique ID (with respect to other atoms/selectors)
  default: InputMode.selectTrip as InputMode, // default value (aka initial value)
  effects: [
    ({ onSet }) => {
      onSet((newID) => {
        savePresets(newID as number, 'inputMode');
      });
    },
  ],
});

const dateFromState: RecoilState<Date> = atom({
  key: 'DateFromState', // unique ID (with respect to other atoms/selectors)
  default: defaultValues.dateFrom, //getDefaultFromDate(), // default value (aka initial value)
  effects: [
    ({ onSet }) => {
      onSet((newID) => {
        savePresets(newID.toString(), 'dateFrom');
      });
    },
  ],
});

const dateToState: RecoilState<Date> = atom({
  key: 'DateToState', // unique ID (with respect to other atoms/selectors)
  default: defaultValues.dateTo, // default value (aka initial value)
  effects: [
    ({ onSet }) => {
      onSet((newID) => {
        savePresets(newID.toString(), 'dateTo');
      });
    },
  ],
});

const dateUpdatedState: RecoilState<Date> = atom({
  key: 'DateUpdatedState', // unique ID (with respect to other atoms/selectors)
  default: new Date(), // default value (aka initial value)
});

const leadTimeState: RecoilState<number> = atom({
  key: 'leadTimeState',
  default: 0,
  effects: [
    ({ onSet }) => {
      onSet((newID) => {
        savePresets(newID, 'leadTime');
      });
    },
  ],
});

export {
  InputMode,
  segment,
  inputModeState,
  activeVesselState,
  dateFromState,
  dateToState,
  dateUpdatedState,
  leadTimeState,
};
export type { OptionType };
