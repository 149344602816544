import { useRecoilState } from 'recoil';

import Select from 'react-select';

import { segments, UrlPaths } from '../../core';

import {
  activeVesselState,
  dateFromState,
  dateToState,
  InputMode,
  inputModeState,
  OptionType,
  segment,
} from '../../state/userinput';
import { Vessel } from '../../state/vessels';

import { DateRange, DateRangePicker } from '../common/DateRangePicker';
import { QuickDateSelect } from '../common/QuickDateSelect';

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { defaultValues } from '../../state/defaultValues';
import { getLastSavedValue } from '../../state/presetsStorage';
import { activeTradeState } from '../../state/trades';
import { getVesselListQuery } from '../services/service';
import { TradeList } from './TradeList';

export const TradeSearch = (props: any) => {
  const [vesselsIsLoading, setLoading] = useState(false);
  const [vessels, setVessels] = useState<Vessel[]>([]);

  const [segmentVal, setSegment] = useRecoilState(segment);
  const [activeVessel, setActiveVessel] = useRecoilState(activeVesselState);
  const [dateFrom, setDateFrom] = useRecoilState(dateFromState);
  const [dateTo, setDateTo] = useRecoilState(dateToState);
  const [, setActiveTrade] = useRecoilState(activeTradeState);
  const [, setInputMode] = useRecoilState(inputModeState);
  //just for demonstration
  const [isInlineView, setIsInlineView] = useState(true);

  const history = useHistory();

  const setDateRange = (value: DateRange) => {
    setDateFrom(value.dateFrom);
    setDateTo(value.dateTo);
  };

  const getSelectOptionsFromVessels = (vessels: Vessel[]) =>
    vessels.map((el: any) => ({
      value: el.Imo,
      label: `${el.ShipName} (${el.Imo})`,
    }));

  useEffect(() => {
    const fetchVesselsForSegment = async () => {
      const segment = (getLastSavedValue('activeSegment') as OptionType | undefined) || segmentVal;
      const res = await getVesselListQuery(segment);
      if (res) {
        setVessels(res);
      } else return [];
    };
    fetchVesselsForSegment();
  }, []);

  const onSegmentChange = async (value: any) => {
    setLoading(true);
    setSegment(value);
    const res = await getVesselListQuery(value);
    setVessels(res);
    setActiveVessel(defaultValues.activeVessel);
    setLoading(false);
  };

  return (
    <>
      <div className='jumbotron row'>
        <div className='col-xl-3 col-md-4 '>
          <h3>Segment</h3>
          <Select
            options={segments.map((el) => {
              return {
                value: el.key,
                label: el.name,
              };
            })}
            value={segmentVal}
            onChange={onSegmentChange}
          />
        </div>
        <div className='col-xl-3 col-md-4'>
          <h3>Vessel</h3>
          <Select
            isLoading={vesselsIsLoading}
            options={getSelectOptionsFromVessels(vessels)}
            value={activeVessel}
            onChange={(value) => {
              setActiveVessel(value as OptionType);
            }}
          />
        </div>

        <div className={isInlineView ? 'col-xl-6 col-md-8' : 'col-12'}>
          <h3>Date from/to</h3>
          <div className='d-flex'>
            <div className='mr-3 '>
              <QuickDateSelect />
            </div>
            <DateRangePicker
              from={dateFrom}
              to={dateTo}
              onChange={(value) => setDateRange(value)}
            />
          </div>
        </div>
        <div className='col-12 '>
          <hr />
          <button
            className='btn btn-outline-primary'
            onClick={() => {
              setActiveTrade(undefined);
              setInputMode(InputMode.trackVessel);
              history.push(UrlPaths.TRACK_VESSEL_MAP);
            }}
          >
            Track vessel
          </button>
          {/* just for demonstration */}
        </div>
        <div className='custom-control custom-switch fixed-bottom'>
          <input
            type='checkbox'
            className='custom-control-input'
            id='customSwitch1'
            onClick={() => {
              setIsInlineView(!isInlineView);
            }}
          />
          <label className='custom-control-label' htmlFor='customSwitch1'></label>
        </div>
      </div>

      <TradeList />
    </>
  );
};
